.App {
  text-align: center;
}

@font-face {
  font-family: 'AT_Font';
  src: url('./styles/font/Manrope-VariableFont_wght.ttf') format('truetype');
}

 
body, html {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.5;
  scroll-behavior: smooth;
  padding-right: 0!important;
}