.cart-content {
    padding-bottom: 2.857em;
}


.container.title h1.page-title.text-uppercase {
    text-transform: none!important;
    font-family: 'Montserrat', sans-serif;
    background: none;
    color: #272727;
    letter-spacing: 0;
    font-size: 1.929rem;
    padding: 0;
    margin: 25px 5px 45px 4%;
    font-weight: 700;
}

.product-info {
    margin-bottom: 0.313em;
    padding: 0.625em;
}

.cart-page .product-info {
    font-size: 1em;
    padding: 16px;
}

.item-image {
    height: 7em;
    width: 7em;
    margin-right: 0.938em;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.product-info .item-image {
    margin-right: 1.07143em;
    height: auto;
}

.item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    max-height: 5.625em;
}

.product-info .item-image img {
    max-height: 98px;
    min-height: 98px;
    max-width: 98px;
    min-width: 98px;
}

.item-attributes {
    display: inline-block;
    vertical-align: top;
}

.product-info .item-attributes {
    font-family: 'Montserrat', sans-serif;
    margin-top: 1em;
}

.line-item-attributes {
    font-size: .813rem;
    margin: 0;
}

.cart-page .line-item-attributes {
    margin: 2px 0;
}

.cart-page .line-item-attributes.collection-name {
    margin-top: 14px;
}

.product-info .item-attributes .line-item-attributes.text-uppercase {
    text-transform: none!important;
}

.product-info .item-attributes .line-item-attributes.collection-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.071rem;
}

.line-item-attributes.product-name.font-weight-bold.text-uppercase::before {
    content: none;
    font-family: 'Montserrat', sans-serif;
}

.line-item-attributes.product-name.font-weight-bold.text-uppercase {
    font-family: 'Montserrat', sans-serif;
}

.line-item-attributes {
    font-size: .813rem;
    margin: 0;
}

.cart-page .line-item-attributes {
    margin: 2px 0;
}

.cart-page .line-item-attributes.product-color, .cart-page .line-item-attributes.product-name {
    font-weight: 600;
}

.product-info .item-attributes .line-item-attributes.text-uppercase {
    text-transform: none!important;
}

.product-info .item-attributes .line-item-attributes.product-name {
    font-size: .952rem;
    font-weight: 600;
}

.product-info .item-attributes .line-item-attributes.product-color {
    font-size: .952rem;
}

.container .regular-retail-price {
    font-family: 'Montserrat', sans-serif;
    font-size: .929rem;
    margin-top: -29px;
}

.container .product-card-footer {
    text-align: right;
    font-size: .929rem;
}

.container-applied-promotion, .container-applied-promotion .line-item-promo {
    display: inline-block;
}

.container .product-card-footer .container-applied-promotion {
    padding: 0;
}

.container-applied-promotion, .container-applied-promotion .line-item-promo {
    display: inline-block;
}

.tooltip-explanations {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.container-applied-promotion .line-item-promo div {
    display: block;
    color: #272727;
    padding: 3px;
}

.container .product-card-footer .container-applied-promotion .line-item-final-price {
    color: #d11e47;
    font-family: 'Montserrat', sans-serif;
}

.line-item-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-info .remove-btn-lg {
    border: none;
    border-radius: 0;
    font-size: 1.625em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    z-index: 1;
}

.product-info .remove-btn-lg {
    background-color: transparent;
    bottom: auto;
    color: #000;
    font-size: 2.1429rem;
    margin: 0;
}

.cart-page .product-info .remove-btn-lg {
    right: 0.64286rem;
    top: -0.5rem;
}

.cart-page .product-info .remove-btn-lg span {
    position: relative;
    color: #fff;
} 

.cart-page .sub-total-block {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    box-shadow: 0 1px 5px -1px #c2c2c2;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 1.014rem 0.571rem;
    font-size: .929rem;
}
 

.cart-page .shipping-method {
    text-align: right;
    padding: 10px 1.25rem;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.cart-page .shipping-method .col-12 {
    padding: 0;
}

.promotion-information {
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
}

.cart-page .promotion-information {
    margin: 0;
    padding: 5px 1.25rem;
    text-align: right;
}

.cart-page .promotion-information:first-child {
    padding-top: 10px;
}

.cart-page .promotion-information:last-child {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.container .cart-total {
    font-size: 1.1429rem;
    padding: 1.429rem 2.143rem 1.429rem 2.25rem;
}

.container .cart-total .w-100 {
    font-size: .952rem;
    margin-bottom: 5px;
}

.grand-total {
    font-size: 1em;
    font-weight: 600;
}

.container .cart-total .w-100 {
    font-size: .952rem;
    margin-bottom: 5px;
}

.container .cart-total .w-100.grand-total {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.07rem;
}

.cart-page .cart-promo-code {
    padding-top: 20px;
}

.cusInfo-box {
    margin-left: 5px;
    width: 100%;
    padding-right: 20px;
}

.checkOut-cusInfo {
    display: block;
    box-shadow: 0 1px 5px -1px #c2c2c2;
    margin-bottom: 0.5rem;
    padding: 1.014rem 0.571rem;
    width: 100%;
}

.cusInfo-box label {
    position: absolute;
    left: 0.5em;
    top: 0.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    color: #999999;
}

.cusInfo-box input.input,.cusInfo-box select {
    box-shadow: 0 0 0 1.2px #d9d9d9;
    transition: all 0.2s ease-out;
    background-color: white;
    color: #333333;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 1.4em 2.1em 0.5em 0.5em;
    word-break: normal;
    margin-bottom: 10px;
    border: none;
}

.cus-input {
    position : relative;
    width: 49%;
    display : inline-block
}

.payment-method-box {
    box-shadow: 0 0 0 1px #d9d9d9;
    border-radius: 4px;
    background: #fff;
    color: #737373;
    margin-top: 1em;    
}

.payment-method {
    padding: 1.4em 2.1em 1.5em 0.9em;
    border-bottom: solid 1px #d9d9d9;
} 

.radio-label {
    display: flex !important;
    cursor: pointer !important;
    align-items: center;
    width: auto;
}

.input-radio {
    width: 18px;
    height: 18px;
    box-shadow: 0 0 0 0 #338dbc inset;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    vertical-align: -4px;
    outline: 0;
    border: solid 1px #d9d9d9;
    border-radius: 50%;
}

.input-radio:checked {
    border: none;
    box-shadow: 0 0 0 10px #338dbc inset;
}

.input-radio:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    transition: all 0.2s ease-in-out 0.1s;
    width: 4px;
    height: 4px;
    margin-left: -2px;
    margin-top: -2px;
    background-color: #fff;
    border-radius: 50%;
    transform: scale(1);
    opacity: 1;


}

.radio-content-input {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.radio-content-input .main-img {
    margin-right: 10px;
    display: flex;
    align-self: center;
    width: 40px;
    height: 40px;
}

.checkout-continue {
    position: fixed;
    bottom: 0;
    z-index: 1;
    padding-right: 0;
    padding-left: 0;
}

.radio-label-primary {
    display: table-cell;
    width: 100%;
}

.checkout-continue {
    z-index: 11;
    position: static;
    padding-top: 1.429rem;
    text-align: center;
    box-shadow: none;
    margin-top: 30px;
}

.checkout-and-applepay {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.714em;
    -ms-flex-pack: center;
    justify-content: center;
}

.checkout-btn.col {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    font-weight: 700;
    margin-top: 15px;
}
.checkout-btn {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    box-shadow: 0 2px 7px -2px #333;
    max-width: 240px;
    font-size: 1.0714rem;
    padding: 0.283rem 0 0.5rem;
    color: #fff;
    background-color: #272727;
    padding: .5em 2em;
}

.checkout-btn:hover {
    
    color: #272727;
    background-color: #fff;
    border: 1px solid #f5f5f5;
}

.fieldset .field {
    width: 100%;
    float: left;
    padding: 0.45em;
    box-sizing: border-box;
}

.fieldset .field .field-input-btn-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fieldset .field .field-input-wrapper {
    position: relative;
}

.fieldset .field .field-input-btn-wrapper .field-input-wrapper {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.fieldset .field .field-input-wrapper .field-label {
    font-size: 0.85714em;
    font-weight: normal;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 0.93333em;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
    filter: alpha(opacity=0);
    color: #999999;
    transition: all 0.2s ease-out;
    margin: 0.5em 0;
    margin-top: 0.3em;
    display: block;
}

.fieldset .field.field-show-floating-label .field-input-wrapper .field-label {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    filter: alpha(opacity=100);
}

.fieldset .field .field-input-wrapper .field-input {
    border: none;
    box-shadow: 0 0 0 1px #d9d9d9;
    transition: all 0.2s ease-out;
    background-color: white;
    color: #333333;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.94em 2.8em 0.94em 0.8em;
    word-break: normal;
}

.fieldset .field .field-input-wrapper .field-input:focus, .cusInfo-box input:focus {
    outline: none;
    box-shadow: 0 0 0 2px #338dbc;
}

.fieldset .btn {
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;
    padding: 1.4em 1.7em;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    position: relative;
    background: #338dbc;
    color: white;
}

.fieldset .btn:hover {
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;
    padding: 1.4em 1.7em;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    position: relative;
    background: #49a2d2;
    color: white;
}

.fieldset .field .field-input-btn-wrapper .field-input-btn {
    width: auto;
    margin-left: 0.9em;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
}


@media (max-width: 767.98px) {
    
    .cus-input select {
        padding : 1em 2.1em 1em 0.5em
    }


}

@media (min-width: 576px){
    .checkout-continue {
        position: static;
        padding-right: 0.938em;
        padding-left: 0.938em;
    }
}
@media (min-width: 768px)
{
    .container .cart-total .w-100.grand-total {
        font-size: 1.186rem;
    }
    .container .cart-total .w-100 {
        font-size: 1.0714rem;
    }
    .cart-page .sub-total-block {
        font-size: 1rem;
    }
    .container-applied-promotion .line-item-promo {
        font-size: 1.0714rem;
    }
    .container .product-card-footer {
        font-size: 1.0714rem;
    }
    .container .regular-retail-price {
        font-size: 1.0714rem;
    }
    .product-info .item-attributes .line-item-attributes.product-color {
        font-size: 1.0714rem;
    }
    .container.title {
        margin-top: 2.857rem;
    }
    .container.title h1.page-title.text-uppercase {
        left: 0;
        margin: 25px 5px 55px;
    }
    .product-info {
        height: auto;
    }
    .product-info .item-image {
        margin-right: 1.07143em;
        height: auto;
    }
    .product-info .item-attributes {
        padding: 20px 15px;
    }
    .product-info .item-attributes {
        margin-top: 0;
    }
    .product-info .item-attributes .line-item-attributes.collection-name:first-child {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .product-info .item-attributes .line-item-attributes.product-name {
        font-size: 1.0714rem;
    }
}

@media (min-width: 992px)
{
    .container-applied-promotion .line-item-promo {
        font-size: 1.142rem;
    }
    .container .product-card-footer {
        font-size: 1.4286rem;
    }
    .container .regular-retail-price {
        font-size: 1.4286rem;
    }
    .container.title h1.page-title.text-uppercase {
        font-size: 2.1429rem;
    }
    .cart-page .product-info .item-image {
        margin-left: 0.357rem;
    }
    .cart-page .product-info .item-attributes {
        padding-left: 1.429rem;
        padding-right: 1.429rem;
    }
}

@media (min-width: 1200px)
{
    .checkout-btn {
        font-size: 1.2rem;
    }
    .container .cart-total .w-100.grand-total {
        font-size: 1.4286rem;
    }
    .container .cart-total .w-100 {
        font-size: 1.142rem;
    }
    .product-info .item-attributes .line-item-attributes.product-color {
        font-size: 1.142rem;
    }
    .product-info .item-attributes .line-item-attributes.collection-name {
        font-size: 1.3rem;
    }
    .product-info .item-attributes .line-item-attributes.product-name {
        font-size: 1.142rem;
    }
}

@media (min-width: 1600px)
{
    .container.cart-page .checkout-btn {
    font-size: 1.4286rem;
}
    .container .cart-total .w-100.grand-total {
        font-size: 1.6429rem;
    }
    .cart-page .sub-total-block {
        font-size: 1.1429rem;
    }
    .product-info {
        font-size: 1.2857rem;
    }
    .product-info .item-attributes .line-item-attributes.collection-name {
        font-size: 1.42857rem;
    }
    .product-info .item-attributes .line-item-attributes.collection-name:first-child {
        margin-top: 0;
    }
}

.product-info {
    box-shadow: 0 1px 5px -1px #c2c2c2;
    padding: 16px;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}