

.product-detail-main {
    max-width: 1600px;
}

/* .breadcrumbs-wrapper {
    display: none;
} */

.breadcrumbs-wrapper .breadcrumb {
    background: 0 0;
    border-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: .786rem;
}

.breadcrumbs-wrapper .breadcrumb li.breadcrumb-item {
    color: #979797;
    padding-right: 0.214em;
}

.thumbnail-image-section .owl-carousel {
    display: block;
}

.thumbnail-image-section .thumbnail-item {
    margin-bottom: 5px;
    display: block;
    cursor: pointer;
}

.thumbnail-image-section .thumbnail-item i, .thumbnail-image-section .thumbnail-item img {
    display: block;
    margin: 0 auto;
    max-width: 45px;
    height: auto;
    opacity: .5;
}

.thumbnail-image-section .thumbnail-item.active i, .thumbnail-image-section .thumbnail-item.active img {
    opacity: 1;
}

.product-top-content {
    position: relative;
    padding: 0.429em 0.857em;
    padding-top: 20px;
}

.product-collection {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.571rem;
    font-weight: 600;
    margin-bottom: 0;
}

.product-wrapper .view-collection-link {
    line-height: .667;
    margin-bottom: 0.814rem;
}

.product-wrapper .view-collection-link a {
    color: #757575;
    text-decoration: underline;
    font-size: .857rem;
}

.product-name {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    position: relative;
    margin-bottom: 0.233em;
    font-weight: 900;
}

.product-detail-images {
    position: relative;
}

.block-radius {
    border-radius: 25px;
    background-color: #fff;
    text-align: center;
    padding-top: 0.357em;
    padding-bottom: 0.357em;
    margin-bottom: 0.429em;
}

.primary-images {
    position: relative;
    z-index: 2;
    padding: 1.25em 0.5rem 0;
    min-height: calc(150vw - 0.3em - 22.35rem);
}

.primary-images .owl-carousel {
    margin-left: auto;
    margin-right: auto;
    background: 0 0;
    padding-bottom: 1em;
}

.primary-images .owl-carousel, .primary-images .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: auto;
    touch-action: auto;
}

.primary-images .owl-carousel-item {
    position: relative;
    padding: 0 6.929rem;
}

.primary-images .carousel .pdp-primary-image {
    margin-left: auto;
    margin-right: auto;
}


.product-wrapper.color-black:before,.color-black .product-top-content:before {
    background: linear-gradient(90deg,#231f20,#b1afb0);
    background: -webkit-gradient(to right,#231f20,#b1afb0);
    background: -o-linear-gradient(to right,#231f20,#b1afb0);
}
.product-wrapper.color-blue:before,.color-blue .product-top-content:before,.product-wrapper.color-navy:before,.color-navy .product-top-content:before {
    background: linear-gradient(90deg,#003454,#6ac6b1);
    background: -webkit-gradient(to right,#003454,#6ac6b1);
    background: -o-linear-gradient(to right,#003454,#6ac6b1);
}
.product-wrapper.color-green:before,.color-green .product-top-content:before {
    background: linear-gradient(90deg,#81c341,#ffc113);
    background: -webkit-gradient(to right,#81c341,#ffc113);
    background: -o-linear-gradient(to right,#81c341,#ffc113);
}
.product-wrapper.color-lightblue:before,.color-lightblue .product-top-content:before {
    background: linear-gradient(90deg,#68fffa,#1c55e3);
    background: -webkit-gradient(to right,#68fffa,#1c55e3);
    background: -o-linear-gradient(to right,#68fffa,#1c55e3);
}
.product-wrapper.color-grey:before,.product-wrapper.color-silver:before,.color-grey .product-top-content:before,.color-silver .product-top-content:before {
    background: linear-gradient(90deg,#a6a7aa,#e4e4e4);
    background: -webkit-gradient(to right,#a6a7aa,#e4e4e4);
    background: -o-linear-gradient(to right,#a6a7aa,#e4e4e4);
}
.product-wrapper.color-red:before,.color-red .product-top-content:before {
    background: linear-gradient(90deg,#f30b0b,#f37a21);
    background: -webkit-gradient(to right,#f30b0b,#f37a21);
    background: -o-linear-gradient(to right,#f30b0b,#f37a21);
}
.product-wrapper.color-purple:before,.color-purple .product-top-content:before {
    background: linear-gradient(90deg,#c31e8c,#04559f);
    background: -webkit-gradient(to right,#c31e8c,#04559f);
    background: -o-linear-gradient(to right,#c31e8c,#04559f);
}
.product-wrapper.color-white:before,.color-white .product-top-content:before {
    background: linear-gradient(90deg,#f3f3f3,#c8c7c6);
    background: -webkit-gradient(to right,#f3f3f3,#c8c7c6);
    background: -o-linear-gradient(to right,#f3f3f3,#c8c7c6);
}
.product-wrapper.color-yellow:before,.color-yellow .product-top-content:before,.product-wrapper.color-beige:before,.color-beige .product-top-content:before {
    background: linear-gradient(90deg,#ffc113,#81c341);
    background: -webkit-gradient(to right,#ffc113,#81c341);
    background: -o-linear-gradient(to right,#ffc113,#81c341);
}
.product-wrapper.color-orange:before,.color-orange .product-top-content:before {
    background: linear-gradient(90deg, #f37a21, #d31e47);
    background: -webkit-gradient(to right, #f37a21, #d31e47);
    background: -o-linear-gradient(to right, #f37a21, #d31e47);
}
.product-wrapper.color-pink:before,.color-pink .product-top-content:before {
    
    background: linear-gradient(90deg,#ee99b4,#fbe6eb);
    background: -webkit-gradient(to right,#ee99b4,#fbe6eb);
    background: -o-linear-gradient(to right,#ee99b4,#fbe6eb);
}
.product-wrapper.color-brown:before,.color-brown .product-top-content:before {
    background: linear-gradient(90deg, #8B4513, #FFA500);
    background: -webkit-gradient(to right, #8B4513, #FFA500);
    background: -o-linear-gradient(to right, #8B4513, #FFA500);
}

.product-top-content:before {
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg,#d11e47,#ef7922);
    background: -webkit-gradient(to right,#d11e47,#ef7922);
    background: -o-linear-gradient(to right,#d11e47,#ef7922);
}  

.product-top-content:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 51%;
    right: 1px;
    bottom: 0;
    background: linear-gradient(0deg,#fff 20%,transparent 80%);
    background: -webkit-gradient(to top,#fff 20%,transparent 80%);
    background: -o-linear-gradient(to top,#fff 20%,transparent 80%);
}

.product-name:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 1.333em;
    background-color: #272727;
    top: 100%;
    margin-top: 0.467em;
    transform: translateX(-50%);
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.product-attribute-wrapper {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.display_table table {
    width: 100%;
}
.display_table th,
.display_table td {
    border: 1px solid black;
    padding: 5px;
    text-align: center;
  }



.product-attribute-wrapper .product-variant-size {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 0.5em;
    padding-bottom: 2em;
}

.product-variant-size-list {
    margin-bottom: 1.286em;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.product-variant-size-list h4 {
    display: none;
}

.product-variant-size-list>.row {
    -ms-flex-pack: center;
    justify-content: center;
}

.product-variant-size-list .variant-item {
    text-align: center;
}

.product-attribute-wrapper .attr-productSize {
    display: none;
}

.product-attribute-wrapper .product-variant-size .attr-productSize {
    display: block;
}

.product-variant-size-list .variant-image-size {
    margin-bottom: 0.714em;
}

.product-variant-size-list .size-item .attr-selector {
    display: inline-block;
    padding: 0.6em 1.2em 0.45em;
    font-family: 'Montserrat', sans-serif !important;
    font-size: .8rem;
    line-height: 1;
    border-radius: 15px;
    box-shadow: 0 1px 3px -1px #272727;
    height: 40px;
    overflow: hidden;
    max-width: 100%;
    min-width: 60%;
    text-align: center;
}

.product-variant-size-list .attr-item.selected .size-item .attr-selector {
    font-size: .8rem;
    min-width: 9em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    color: #fff;
    background-color: #272727;
    border-color: #272727;
    display: inline-block;
    padding: 0.6em 1.2em 0.45em;
    font-family: 'Montserrat', sans-serif !important;
    font-size: .857rem;
    min-width: inherit;
    line-height: 1;
}

.product-attribute-wrapper .product-attributes {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.product-attribute-wrapper .attribute {
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
}

.product-attribute-wrapper [data-attr=color] .attribute {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.product-attribute-wrapper [data-attr=color] .color-quantity {
    text-align: center;
    color: #757575;
    font-size: .786rem;
    margin-top: 1em;
    margin-bottom: 1em;
}

.product-attribute-wrapper .color-swatchs {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 1.857em;
    margin-right: 1.857em;
    padding-left: 2em;
    padding-right: 2em;
    width: auto;
}

.product-attribute-wrapper .color-swatchs .attr-item {
    min-width: 27px;
    text-align: center;
}

.product-attribute-wrapper .color-swatchs .attr-selector, .product-attribute-wrapper .color-swatchs .color-value {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 27px;
    border-radius: 50%;
}

.product-attribute-wrapper .color-swatchs .attr-item.selected .attr-selector, .product-attribute-wrapper .color-swatchs .attr-item.selected .color-value {
    width: 27px;
    height: 27px;
}

.prices-add-to-cart-actions {
    position: relative;
    background: 0 0;
    padding: 1.786em 0.714em 0;
    background-color: #f6f7f9;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    left: auto;
    bottom: auto;
    overflow: hidden;
    z-index: 1;
    box-shadow: none;
}

.product-detail-section {
    border-bottom: 0;
}

.prices, .prices-add-to-cart-actions .price {
    text-align: center;
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}

.prices-add-to-cart-actions .prices {
    padding: 0;
}

.prices-add-to-cart-actions .sales {
    font-size: 2.619rem;
    color: #272727;
    font-weight: 400;
}

.prices-add-to-cart-actions .sales.promotion-price {
    color: #d21245;
    margin-right: 5px;
}

.prices-add-to-cart-actions .strike-through {
    color: #272727;
}

.prices-add-to-cart-actions .cart-and-ipay, .prices-add-to-cart-actions .luggage-cover {
    text-align: center;
    padding-bottom: 0;
    margin-top: 1em;
}

#product-informations .prices-add-to-cart-actions .quick-checkout {
    display: inline-block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    color: #fff;
    background-color: #272727;
    border-color: #272727;
    min-width: 42%;
    padding: 0.725em 1.67em;
    margin: 0 0.667em;
    font-size: .857rem;
    width: auto;
    text-transform: uppercase;
}

#product-informations .prices-add-to-cart-actions .add-to-cart {
    display: inline-block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color 0.5s ease-in, background 0.5s ease-in, border-color 0.5s ease-in, box-shadow 0.5s ease-in;
    box-shadow: 0 2px 7px -2px #333;
    color: #fff;
    background: linear-gradient(90deg, #d31e47, #f37a21);
    border: none;
    min-width: 42%;
    padding: 0.725em 1.67em;
    margin: 0 0.667em;
    font-size: .857rem;
}

#product-informations .prices-add-to-cart-actions .add-to-cart:hover {
    color: #fff;
    background: linear-gradient(90deg, #272727, #272727);
}


#product-informations .prices-add-to-cart-actions .quick-checkout:hover {
    color: #272727;
    background-color: #fff;
    box-shadow: 0 2px 7px -2px #333;
    border: 1px solid #f5f5f5;
}

.product-detail-section .below-add-to-cart {
    margin: 0 -1.071rem;
    -ms-flex-pack: center;
    justify-content: center;
}

.size-chart, div.availability {
    margin-top: 1.071em;
}

.prices-add-to-cart-actions .product-availability {
    text-align: center;
    width: auto;
    padding: 0;
    font-size: .786rem;
}

.product-detail-section .below-add-to-cart .product-availability {
    -ms-flex-order: 2;
    order: 2;
    padding-bottom: 5px;
}

.prices-add-to-cart-actions .product-availability .row {
    margin: 0;
}

.prices-add-to-cart-actions .product-availability .col-sm-4 {
    display: none;
}

.prices-add-to-cart-actions.revised-layout .product-availability .col-sm-4 {
    display: block;
}

.prices-add-to-cart-actions.revised-layout .product-availability .row .col-12.col-sm-4, .prices-add-to-cart-actions.revised-layout .product-availability .row .col-12.col-sm-8 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.prices-add-to-cart-actions.revised-layout .product-availability label.availability {
    color: #757575;
}

.prices-add-to-cart-actions .product-availability .availability-msg {
    margin-bottom: 0;
    padding-left: 0;
    white-space: nowrap;
}

.prices-add-to-cart-actions .product-availability .availability-msg li {
    list-style: none;
}

.prices-add-to-cart-actions.revised-layout .product-availability .availability-msg li div {
    display: inline-block;
}

.prices-add-to-cart-actions.revised-layout .product-availability .availability-msg li div:before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #80c242;
    /* red color if not availabe */
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    margin-bottom: 1px;
}

.prices-add-to-cart-actions.revised-layout .product-availability .availability-msg li div.notAvail:before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #f90606;
    /* red color if not availabe */
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    margin-bottom: 1px;
}

.product-image-360-section, .product-information-inner, .size-comparison-checker-section, .store-inventory-section {
    margin-left: -15px;
    margin-right: -15px;
}

.product-information-inner {
    margin-left: -15px;
    margin-right: -15px;
}

.product-image-360-section .card, .product-information-inner .card, .size-comparison-checker-section .card, .store-inventory-section .card {
    border-left: 0;
    border-right: 0;
    background: 0 0;
    border-radius: 0;
}   

.product-image-360-section>.card, .product-information-inner>.card, .size-comparison-checker-section>.card, .store-inventory-section>.card {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #f6f7f9;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.product-image-360-section .card-header, .product-information-inner .card-header, .size-comparison-checker-section .card-header, .store-inventory-section .card-header {
    font-family: 'Montserrat', sans-serif !important;
    background: 0 0;
    border: 0;
    padding: 0.857em 1em;
    border-radius: 0;
}

.product-image-360-section .card-header h2, .product-information-inner .card-header h2, .size-comparison-checker-section .card-header h2, .store-inventory-section .card-header h2 {
    font-size: 1rem;
    position: relative;
}

.product-image-360-section .card-header .btn-link, .product-information-inner .card-header .btn-link, .size-comparison-checker-section .card-header .btn-link, .store-inventory-section .card-header .btn-link {
    color: #272727;
    font-size: .857rem;
    padding: 0;
    width: 100%;
    text-align: left;
    border: 0;
    text-decoration: none;
}

.product-sku {
    padding-left: 1.89em;
}

.product-sku .row {
    margin: 0;
    padding-top: 0.28em;
    padding-bottom: 0.28em;
}

.product-sku .row .col-4, .product-sku .row .col-8 {
    padding: 0;
    -ms-flex: auto;
    flex: auto;
    max-width: none;
    width: auto;
}

.product-sku .row .col-label {
    margin-right: 1em;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
}

.product-materials {
    padding-left: 1.89em;
}

.product-materials ul {
    margin-bottom: 0;
    padding-left: 0;
}

.product-materials ul li {
    list-style: none;
    padding-top: 0.28em;
    padding-bottom: 0.28em;
}

.product-variant-size-list .size-item .attr-selector:hover {
    color: white;
    background-color: #000;
}

.block-sidebar .sticky-column{
    position: sticky;
    top: 220px;
}

.sticky-column.product-detail-information-content {
    
  position: sticky;
  top: 150px;
  height: auto
}

.product-information-inner .card {
    padding-left: 20px;
    padding-right: 20px;

}

@media(min-width: 768px)
{
    .product-top-content {
        padding-top: 30px;
    }
    .sticky-column.product-detail-information-content {
        top: 0px;
    }
    .prices-add-to-cart-actions.revised-layout .product-availability.col-12.col-md-10 {
        margin-right: 0;
        width: 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .prices-add-to-cart-actions .product-availability {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .product-wrapper .view-collection-link a {
        font-size: 1.0714rem;
    }
    .primary-images {
        padding-top: 2.857em;
        min-height: 31.857em;
    }
    .primary-images .owl-carousel {
        display: block;
    }
}

@media (min-width: 992px)
{
    
    .product-top-content {
        position: relative;
        padding: 0.429em 0.857em;
        padding-top: 0px;
    }
    .product-information-inner .card {
        padding-left: 5px;
        padding-right: 5px;
    
    }
    .sticky-column.product-detail-information-content {
        top: 30px;
    }
    .product-image-360-section .card-header .btn-link, .product-information-inner .card-header .btn-link, .size-comparison-checker-section .card-header .btn-link, .store-inventory-section .card-header .btn-link {
        font-size: 1rem;
    }
    .product-image-360-section, .product-information-inner, .size-comparison-checker-section, .store-inventory-section {
        margin-left: 0;
        margin-right: 0;
    }
    .product-information-inner {
        padding-bottom: 3em;
        margin-left: 0;
        margin-right: 0;
    }
    .prices-add-to-cart-actions .product-availability {
        font-size: .857rem;
        margin-bottom: 1.574em;
    }
    #product-informations .prices-add-to-cart-actions .add-to-cart {
        min-width: 60%;
        padding: 0.6em 1.67em;
        font-size: 1rem;
    }
    #product-informations .prices-add-to-cart-actions .add-to-cart {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
    #product-informations .prices-add-to-cart-actions .quick-checkout {
        min-width: 60%;
        padding: 0.6em 1.67em;
        font-size: 1rem;
    }
    .prices-add-to-cart-actions .sales {
        font-size: 2rem;
    }
    .prices-add-to-cart-actions {
        border-top: 1px solid #6b6b6b;
        margin-left: 0;
        margin-right: 0;
    }
    .product-detail-info-top, .product-detail-section {
        padding-bottom: 0.429em;
        border-bottom: 1px solid #6b6b6b;
    }
    .product-attribute-wrapper .color-swatchs .attr-selector:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
        border-radius: 50%;
    }
    .product-attribute-wrapper .color-swatchs .attr-selector, .product-attribute-wrapper .color-swatchs .color-value {
        width: 20px;
        height: 20px;
        line-height: 1;
    }
    .product-attribute-wrapper .color-swatchs .attr-selector {
        position: relative;
        padding: 2px;
    }
    .product-attribute-wrapper .color-swatchs .attr-item {
        margin-right: 5px;
    }
    .product-attribute-wrapper .color-swatchs {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .product-attribute-wrapper [data-attr=color] .color-quantity {
        text-align: left;
        font-size: .857rem;
    }
    .product-attribute-wrapper [data-attr=color] .attribute {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .product-attribute-wrapper .product-attributes {
        padding-top: 1.0em;
        padding-bottom: 2.0em;
        border-top: 1px solid #6b6b6b;
        flex: 1 1 auto;
        -ms-flex: 1 1 auto;
    }
    .product-variant-size-list .attr-item.selected .size-item .attr-selector {
        font-size: .6rem;
    }
    .product-variant-size-list .size-item .attr-selector {
        font-size: .6rem;
    }
    .product-variant-size-list {
        margin-bottom: 1.5em;
        width: 100%;
    }
    .product-variant-size-list>.row {
        width: 90%;
        margin: 0 auto;
    }
    .product-variant-size-list h4 {
        display: block;
        margin-bottom: 0;
        font-size: .857rem;
        color: #757575;
    }
    .product-attribute-wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .product-detail-info-top, .product-detail-section {
        padding-bottom: 0.429em;
        border-bottom: 1px solid #6b6b6b;
    }
    .primary-images .carousel .pdp-primary-image {
        max-width: 24.714em;
        /* min-height: 34.714em; */ 
    }
    .product-detail-images {
        margin-top: -4.8em;
    }
    .product-wrapper {
        position: relative;
        padding-top: 7em;
    }
    .product-wrapper:before {
        z-index: -2;
        background: linear-gradient(90deg,#d11e47,#ef7922);
        background: -webkit-gradient(to right,#d11e47,#ef7922);
        background: -o-linear-gradient(to right,#d11e47,#ef7922);
    }
    .product-wrapper:after, .product-wrapper:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 10em;
    }
    .block-sidebar {
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
        top: 220px;
    }
    .block-sidebar-fake {
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
    }
    .breadcrumbs-wrapper {
        display: block;
        margin-bottom: 1.5em;
    }
    .block-main-detail {
        -ms-flex: 0 0 82%;
        flex: 0 0 82%;
        max-width: 82%;
        display: flex;
        flex-direction: column;
    }
    .product-wrapper:after, .product-wrapper:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 10em;
    }
    .product-wrapper:after {
        z-index: -1;
        background: linear-gradient(0deg,#fff 20%,transparent 80%);
        background: -webkit-gradient(to top,#fff 20%,transparent 80%);
        background: -o-linear-gradient(to top,#fff 20%,transparent 80%);
    }
    .product-collection {
        font-size: 2.929rem;
        margin-bottom: 0;
    }
    .product-wrapper .view-collection-link {
        margin-top: 3px;
        margin-bottom: 20px;
    }
    .product-wrapper .view-collection-link a {
        font-size: .929rem;
    }
    .product-name {
        margin-bottom: 2.857em;
        font-size: 1.1429rem;
    }
    .product-detail-images.block-radius {
        padding-top: 0;
    }
    .primary-images {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        background-color: #fff;
        min-height: auto;
        border-radius: 25px;
    }
    .primary-images:after, .primary-images:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 28.57em;
        z-index: -2;
        border-radius: 25px;
        box-shadow: 0 0 10px -3px #272727;
    }
    .primary-images:after {
        z-index: -1;
        left: -10px;
        right: -10px;
        box-shadow: none;
        background: linear-gradient(180deg,transparent,#fff);
        background: -webkit-gradient(to bottom,transparent,#fff);
        background: -o-linear-gradient(to bottom,transparent,#fff);
    }
    .primary-images .owl-carousel {
        padding: 0;
        background: #fff;
    }
    .primary-images .owl-carousel-item {
        padding-left: 2.1em;
        padding-right: 2.1em;
        margin-bottom: 1em;
    }
    .product-top-content:before, .product-top-content:after {
        display: none;
    }
    .product-detail-informations {
        padding-left: 6.5em;
        flex: 1;
    }
    .product-name:before {
        left: 0;
        width: 3.75em;
        height: 0.5em;
        margin-top: 1.25em;
        transform: translateX(0);
        background: linear-gradient(90deg,#d11e47,#ef7922);
        background: -webkit-gradient(to right,#d11e47,#ef7922);
        background: -o-linear-gradient(to right,#d11e47,#ef7922);
        border-radius: 20px;
    }
    .color-black .product-name:before {
        background: linear-gradient(90deg,#231f20,#b1afb0);
        background: -webkit-gradient(to right,#231f20,#b1afb0);
        background: -o-linear-gradient(to right,#231f20,#b1afb0);
    }
    .color-blue .product-name:before,.color-navy .product-name:before {
        background: linear-gradient(90deg,#003454,#6ac6b1);
        background: -webkit-gradient(to right,#003454,#6ac6b1);
        background: -o-linear-gradient(to right,#003454,#6ac6b1);
    }
    .color-green .product-name:before {
        background: linear-gradient(90deg,#81c341,#ffc113);
        background: -webkit-gradient(to right,#81c341,#ffc113);
        background: -o-linear-gradient(to right,#81c341,#ffc113);
    }
    .color-lightblue .product-name:before {
        background: linear-gradient(90deg,#68fffa,#1c55e3);
        background: -webkit-gradient(to right,#68fffa,#1c55e3);
        background: -o-linear-gradient(to right,#68fffa,#1c55e3);
    }
    .color-grey .product-name:before,.color-silver .product-name:before {
        background: linear-gradient(90deg,#a6a7aa,#e4e4e4);
        background: -webkit-gradient(to right,#a6a7aa,#e4e4e4);
        background: -o-linear-gradient(to right,#a6a7aa,#e4e4e4);
    }
    .color-red .product-name:before {
        background: linear-gradient(90deg,#d31e47,#f37a21);
        background: -webkit-gradient(to right,#d31e47,#f37a21);
        background: -o-linear-gradient(to right,#d31e47,#f37a21);
    }
    .color-purple .product-name:before {
        background: linear-gradient(90deg,#c31e8c,#04559f);
        background: -webkit-gradient(to right,#c31e8c,#04559f);
        background: -o-linear-gradient(to right,#c31e8c,#04559f);
    }
    .color-white .product-name:before {
        background: linear-gradient(90deg,#f3f3f3,#c8c7c6);
        background: -webkit-gradient(to right,#f3f3f3,#c8c7c6);
        background: -o-linear-gradient(to right,#f3f3f3,#c8c7c6);
    }
    .color-yellow .product-name:before,.color-beige .product-name:before {
        background: linear-gradient(90deg,#ffc113,#81c341);
        background: -webkit-gradient(to right,#ffc113,#81c341);
        background: -o-linear-gradient(to right,#ffc113,#81c341);
    }
    .color-orange .product-name:before {
        background: linear-gradient(90deg,#f37a21,#d31e47);
        background: -webkit-gradient(to right,#f37a21,#d31e47);
        background: -o-linear-gradient(to right,#f37a21,#d31e47);
    }
    .color-pink .product-name:before {
        background: linear-gradient(90deg,#ee99b4,#fbe6eb);
        background: -webkit-gradient(to right,#ee99b4,#fbe6eb);
        background: -o-linear-gradient(to right,#ee99b4,#fbe6eb);
    }
    .color-brown .product-name:before {
        background: linear-gradient(90deg, #8B4513, #FFA500);
        background: -webkit-gradient(to right, #8B4513, #FFA500);
        background: -o-linear-gradient(to right, #8B4513, #FFA500);
    }
    .product-variant-size {
        position: relative;
    }
    .product-attribute-wrapper .product-variant-size {
        margin-top: 0;
        padding-top: 1.5em;
        padding-bottom: 0;
        flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .product-attribute-wrapper .color-swatchs .color-value {
        width: 100%;
        height: 100%;
    }
    .product-attribute-wrapper .color-swatchs .attr-item.selected .attr-selector {
        width: 20px;
        height: 20px;
    }
    .product-attribute-wrapper .color-swatchs .attr-item.selected .attr-selector:before {
        border-color: #999;
    }
    .product-attribute-wrapper .color-swatchs .attr-item.selected .color-value {
        width: 100%;
        height: 100%;
    }
}    


@media (min-width: 1200px)
{
    
    .product-detail-images {
        margin-top: -4em;
    }
    .product-attribute-wrapper .color-swatchs .attr-item.selected .attr-selector {
        width: 24px;
        height: 24px;
    }
    .product-attribute-wrapper .color-swatchs .attr-selector {
        width: 24px;
        height: 24px;
    }
    .product-detail-main {
        width: 92%;
    }
    .breadcrumbs-wrapper .breadcrumb {
        font-size: .857rem;
    }
    .product-detail-informations {
        padding-left: 7.5em;
    }
    
    .product-variant-size-list .attr-item.selected .size-item .attr-selector {
        font-size: .75rem;
    }
    .product-variant-size-list .size-item .attr-selector {
        font-size: .75rem;
    }
    
    
    .primary-images .carousel .pdp-primary-image {
        max-width: 25.714em;
        /* min-height: 34.714em; */ 
    }
}  


@media (min-width: 1400px)
{
    
    .product-detail-images {
        margin-top: -4.4em;
    }
    .product-variant-size-list .attr-item.selected .size-item .attr-selector {
        font-size: .9rem;
    }
    .product-variant-size-list .size-item .attr-selector {
        font-size: .9rem;
    }
    #product-informations .prices-add-to-cart-actions .add-to-cart {
        padding: 0.725em 1.67em;
        font-size: 1.1429rem;
    }
    #product-informations .prices-add-to-cart-actions .quick-checkout {
        padding: 0.725em 1.67em;
        font-size: 1.1429rem;
    }
    
    .primary-images .carousel .pdp-primary-image {
        max-width: 36.214em;
        /* min-height: 34.714em; */ 
    }
}

@media (min-width: 1600px)
{
    .product-image-360-section .card-header .btn-link, .product-information-inner .card-header .btn-link, .size-comparison-checker-section .card-header .btn-link, .store-inventory-section .card-header .btn-link {
        font-size: 1.1429rem;
    }
    .prices-add-to-cart-actions .sales {
        font-size: 2.5rem;
    }
    .product-attribute-wrapper .color-swatchs .attr-item.selected .attr-selector {
        width: 27px;
        height: 27px;
    }
    .product-attribute-wrapper .color-swatchs .attr-selector {
        width: 27px;
        height: 27px;
    }
    .product-variant-size-list .attr-item.selected .size-item .attr-selector {
        font-size: 1.1429rem;
        padding: 0.5em 1.2em;
    }
    .product-variant-size-list .size-item .attr-selector {
        font-size: 1.1429rem;
        padding: 0.5em 1.2em;
    }
    .primary-images .carousel .pdp-primary-image {
        /* min-height: 41.571em; */
    }
    .thumbnail-image-section .thumbnail-item i, .thumbnail-image-section .thumbnail-item img {
        max-width: 50px;
    }
    .product-collection {
        font-size: 3.2143rem;
    }
    .product-name {
        font-size: 1.4286rem;
    }
    .primary-images {
        padding-top: 3.75em;
    }
    .primary-images .owl-carousel-item {
        padding-left: 2.6em;
        padding-right: 2.6em;
    }
    .product-detail-informations {
        padding-left: 8.5em;
    }
}   



.product-detail-section {
    border-bottom: 0;
}