#pageDefaultTerms .intro-section h1, #pageDefaultContact .intro-section h1, #pageDefaultMember .intro-section h1, #pageDefaultSecurity .intro-section h1, #pageDefaultInsurance .intro-section h1, #pageDefaultReturn .intro-section h1, #pageDefaultOrder .intro-section h1, #pageDefaultReceive .intro-section h1, #pageDefaultPayment .intro-section h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: 800;
    text-align: center;
}

.support-table-receive td {
    border-width: 1px;
}

#samsoniteImg1 {
    font-weight: 400;
    color: #212529;
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
    margin: 10px auto;
}

#samsoniteBrand {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-size: 14px;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
}

#samsoniteBrand picture img{
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
}

#samsoniteBrand .pageAbout-us.page-layout {
    font-weight: 400;
    color: #212529;
    text-align: left;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 15px;
    line-height: 1.6;
}

#samsoniteBrand .pageAbout-us.page-layout .container {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    width: 80%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
}

#samsoniteBrand .pageAbout-us.page-layout .container .row.wrapper-row.pd-page {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: center;
}

#samsoniteBrand .pageAbout-us.page-layout .container .row.wrapper-row.pd-page .col-md-8.col-sm-12.col-12 {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

#samsoniteBrand .pageAbout-us.page-layout .container .row.wrapper-row.pd-page .col-md-8.col-sm-12.col-12 .page-wrapper {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    overflow-x: hidden;
}

#samsoniteBrand .page-wrapper .wrapbox-content-page {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
}

#samsoniteBrand .page-wrapper .wrapbox-content-page .content-page {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
}

#americanTouristerImg1 {
    font-weight: 400;
    color: #212529;
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
    margin: 10px auto;
}

#americanTouristerRow {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#americanTouristerRow .col-md-4 {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

#americanTouristerRow .col-md-8 {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

#americanTouristerRow .col-md-4 img {
    font-weight: 400;
    color: #212529;
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    box-sizing: border-box;
    font-family: Readex Pro, sans-serif !important;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
    padding: 10px;
}

#pageDefaultLipaultParis img {
    max-width: 100%;
    height: auto;
}

#pageDefaultHighSierra img {
    max-width: 100%;
    height: auto;
}

#pageDefaultKamiliant img {
    max-width: 100%;
    height: auto;
}

.pageAbout-us {
    margin-right: -15px;
    margin-left: -15px;
    
    & .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    & p {
        margin: 0 0 10px 0;
        line-height: 21px;
    }

    & .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    & .content .row {
        margin-bottom: 15px;
    }

    & .brand-page {
        padding: 40px 0;
    }

    & .page-title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 30px;
    }
     
    & .page-title:after {
        content: '';
        display: block;
        width: 50px;
        height: 2px;
        background: #333;
        margin-top: 10px;
    }

    & .page-title.text-center:after {
        margin: 10px auto 0;
    }

    & .brand-page .brand-info {
        text-align: center;
        margin-top: -20px;
        margin-bottom: 40px;
    }

    & .brand-page .brand-info .location {
        font-size: 16px;
        color: #999999;
    } 

    & .content ul, .content ol, .content p, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .content img, .content figure {
        margin-bottom: 15px;
        line-height: 25px;
    }

    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }

    & .col-md-6 {
        width: 50%;
    }

    & .brand-page .content .row {
        display: block;
    }
}

.container.discover.posts {
    min-height: 80vh;
    margin-top: 20px;

    & .discover-label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        text-transform: uppercase;
        margin-top: 40px;
        font-weight: 500;
        font-size: 25px;

        @media (min-width: 768px) {
            font-size: 30px;
        }
    }

    & .discover-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: wrap;
        flex-flow: wrap;
        width: 100%;
        margin-top: 40px;
    }

    & .discover-content .discover-card-box {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        padding: 15px;
        width: 50%;

        @media (min-width: 900px){
            width: 25%;
        }
    }

    & a {
        transition: .3s ease-in;
    }

    & a:focus, a:hover {
        text-decoration: none;
        outline: 0;
        color: #272727;
    }

    & .discover-content .discover-card-box .discover-card {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    & .discover-content .discover-card-box .discover-card .card-image {
        /* max-width: 250px; */
        overflow: hidden;
    }

    & .discover-content .discover-card-box .discover-card .card-image img {
        object-fit: cover;
        transition: .2s;
    }

    & .mt-3, .my-3 {
        margin-top: 1rem !important;
    }

    & .text-center {
        text-align: center !important;
    }

    & .card-subtitle, .card-text:last-child {
        margin-bottom: 0;
    }

    & .font-weight-bold {
        font-weight: 700 !important;
    }

    & .discover-content .discover-card-box .discover-card .card-focus-text {
        text-align: center;
        width: 100%;
        height: 80px;
        margin: 0 auto;
        font-size: 17px;
        color: #000;
        transition: .2s;
    }

    & .discover .discover-content .discover-card-box .discover-card .card-focus-text.ellipsis {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & img:hover {
        transform: scale(1.1); /* Điều chỉnh tỉ lệ phóng to ở đây */
    }
}


@media (min-width: 768px)
{
    .intro-section {
        padding-bottom: 30%;
    }
}


@media (min-width: 800px)
{

    #pageDefaultTerms .intro-section h1, #pageDefaultContact .intro-section h1, #pageDefaultMember .intro-section h1, #pageDefaultSecurity .intro-section h1, #pageDefaultInsurance .intro-section h1, #pageDefaultReturn .intro-section h1, #pageDefaultOrder .intro-section h1, #pageDefaultReceive .intro-section h1, #pageDefaultPayment .intro-section h1{
        font-size: 50px;
    }
}


@media (min-width: 992px)
{

    .pageAbout-us {
    
        & .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
            float: left;
        }
    
        & .col-md-6 {
            width: 50%;
        }

        & .col-md-3 {
            width: 25%;
        }
    }
}

@media (min-width: 1200px)
{
    .intro-section {
        height: 500px;
    }
}

.intro-section {
    position: relative;
} 

@media (max-width: 767px)
{
    .intro-section {
        background-position: bottom;
        padding-bottom: 80%;
    }
}

#pageDefaultOrder a:hover, #pageDefaultReturn a:hover, #pageDefaultSecurity a:hover {
    text-decoration: none;
    color : black
}

#pageDefaultOrder a, #pageDefaultReturn a, #pageDefaultSecurity a {
    color: black;
    font-weight: 600;
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

input:focus
{
    border: 12px solid #fff;
    box-shadow: 0 0 3px #fff;
    outline-offset: 0px;
    outline: none;
}

.contact-section-content {
    padding-top: 60px;
    padding-bottom: 30px;
}

.contact-section-content h2 {
    text-align: center;
    position: relative;
    margin-bottom: 40px;
    color: #464646;
}

.contact-section-content h2:after {
    content: '';
    border-top: 2px solid #000;
    position: absolute;
    bottom: -15px;
    width: 70px;
    left: 50%;
    transform: translateX(-50%);
}

.contact-section-content p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #464646;
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
}

.contactInput {
    margin: 0;
    overflow: visible;
    display: block;
    width: 100%;
    height: 33.5pxd;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: none;
    background: #e8e9ea;
    border-radius: 6px;
    border: none;
    margin-bottom: 15px;
    color: #464646;
}

.contactTextArea {
    margin: 0;
    overflow: auto;
    resize: vertical;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: none;
    height: auto;
    min-height: 130px;
    background: #e8e9ea;
    border-radius: 6px;
    border: none;
    margin-bottom: 15px;
    color: #464646;
}

.contactInput:focus {
    box-shadow: none;
    border: none;
}

.contact-section-content .contact-section-form button {
    border-radius: 0px;
    margin-top: 20px;
    font-weight: 700;
    color: #fff;
    background: #000;
}