.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden#HomepageMain {
    background-color: black;
    width: auto;
    height: auto;
    color: #ffba08;
}

.img-fluid.ls-is-cached.lazyloaded {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
    width: 100%;
}

#HomepageMain span {
    box-sizing: border-box;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    user-select: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    height: 4px;
    width: 22px;
    background: #999;
    margin-bottom: 0px;
}


.home-promotion {
    padding: 0 20px;
    position: relative;
    z-index: 1;
}

.homepage .home-promotion {
    margin-top: 15px;
}

.container-wide-screen {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.home-promotion .promotion-content {
    text-align: center;
    position: relative;
    z-index: 1;
}

.home-promotion .luggage-escapes {
    z-index: 2;
}

.home-promotion .container-wide-screen>.row {
    margin-left: -20px;
    margin-right: -20px;
}

.home-promotion .luggage-show {
    position: relative;
    z-index: 1;
    padding: 0;
    min-height: 74vw;
}

img[data-src].lazy-loaded {
    background: 0 0;
}

.home-promotion .promotion-content img[data-src] {
    display: none;
}

.home-promotion .promotion-content img[data-src].lazy-loaded {
    display: block;
}

.home-promotion .promotion-info {
    position: relative;
    margin-left: 0.714em;
    margin-right: 0.714em;
    margin-top: -3.143em;
    padding-top: 1.786em;
    padding-bottom: 1.786em;
    background-color: #fff;
    z-index: 2;
    width: auto;
    -ms-flex: auto;
    flex: auto;
}

.home-promotion .promotion-info .title {
    position: relative;
    margin-bottom: 1.4em;
    font-size: 1.8571rem;
    font-family: 'Montserrat', sans-serif !important;
}

.home-promotion .promotion-content.has-animation .title,
.home-promotion .promotion-content.has-animation .white-button,
.home-promotion .promotion-content.has-animation p {
    opacity: 0;
    filter: alpha(opacity=0);
}

.home-promotion .promotion-content.has-animation.load-animation .title,
.home-promotion .promotion-content.has-animation.load-animation .white-button,
.home-promotion .promotion-content.has-animation.load-animation p {
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.home-promotion .promotion-content.has-animation.load-animation .title {
    animation-delay: .2s;
}

.home-promotion .promotion-info .title:after {
    content: "";
    display: block;
    padding: 3.2px;
    border-radius: 0.7142rem;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 100%;
    width: 1.929rem;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #d11e47, #ef7922);
    background: -webkit-gradient(to right, #d11e47, #ef7922);
    background: -o-linear-gradient(to right, #d11e47, #ef7922);
    margin-top: 0.6em;
}

.home-promotion .promotion-content.has-animation.load-animation p {
    animation-delay: .5s;
    font-weight: 600;
}

.black-button:hover,
.white-button {
    color: #272727;
    background-color: #fff;
    box-shadow: 0 2px 7px -2px #333;
}

.white-button:hover,
.black-button {
    color: #fff;
    background-color: #272727;
    box-shadow: 0 2px 7px -2px #fff;
}

.white-button {
    display: inline-block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    padding: 1em 1.67em;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in, background-color .2s ease-in, border-color .2s ease-in, box-shadow .2s ease-in;
    border: 1px solid #f5f5f5;
    text-align: center;
}

.home-promotion .white-button {
    margin-top: 0.7142rem;
    font-size: 1rem;
    padding: 10px 20px;
    text-align: center;
}

.home-promotion .promotion-content.has-animation.load-animation .white-button {
    animation-delay: .8s;
}

.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.hero-category .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.hero-category {
    margin-top: 2.857em;
    padding-top: 0.714rem;
    padding-bottom: 1.586rem;
    color: #fff;
    position: relative;
    z-index: 1;
}

.hero-category:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #d11e47, #ef7922);
    background: -webkit-gradient(to right, #d11e47, #ef7922);
    background: -o-linear-gradient(to right, #d11e47, #ef7922);
    transform: skew(0deg, 4.7deg);
}

.hero-category .container {
    padding-left: 0;
    padding-right: 0;
}

.hero-category .col {
    padding-left: 0;
    padding-right: 0;
}

.hero-category .item2 {
    margin-top: 3.571rem;
}

.hero-category.has-animation.load-animation .category-info,
.hero-category.has-animation.load-animation .image {
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.hero-category.has-animation.load-animation .item1 .image {
    animation-delay: .2s;
}

.hero-category.has-animation.load-animation .item1 .category-info,
.hero-category.has-animation.load-animation .item2 .image {
    animation-delay: .4s;
}

.hero-category .image {
    margin-bottom: 1em;
    padding-top: 1.6429rem;
    border-bottom-left-radius: 3.929rem;
    border-bottom-right-radius: 4.286rem;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    width: 8.7rem;
    padding-left: 5px;
}

.hero-category .image a {
    display: block;
}

.hero-category .category-info {
    width: 8rem;
    margin: 0 auto;
}

.hero-category.has-animation .category-info,
.hero-category.has-animation .image {
    opacity: 0;
    filter: alpha(opacity=0);
}

.hero-category.has-animation.load-animation .category-info,
.hero-category.has-animation.load-animation .image {
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.hero-category.has-animation.load-animation .item1 .category-info,
.hero-category.has-animation.load-animation .item2 .image {
    animation-delay: .4s;
}

.hero-category.has-animation.load-animation .item2 .category-info {
    animation-delay: .8s;
}

.hero-category .image a:before {
    content: "";
    position: absolute;
    display: block;
    width: 8rem;
    height: 8rem;
    z-index: -1;
    border-radius: 50%;
}

.hero-category .item1 a:before {
    background: #ffc012;
}

.hero-category .item1 a::before {
    background: #ef9500;
    top: 131px;
    margin-left: 2px;
}

.hero-category .col img {
    transition: transform .3s;
}

.hero-category .image img {
    position: relative;
    z-index: 7;
}

.hero-category .item1 img {
    right: -0.186rem;
    bottom: 2px;
}


.hero-category .category-more {
    color: #fff;
    display: block;
    text-decoration: none;
    cursor: pointer;
}

.hero-category .category-more,
.hero-category .category-title {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
}

.hero-category .category-more a,
.hero-category .category-title a {
    color: #fff;
}

.hero-category .item2 a::before {
    background: #800000;
    top: 52px;
}

.hero-category .item2 img {
    bottom: 1.0714rem;
    right: 0rem;
}

.hero-category .item2 img:hover,
.hero-category .item1 img:hover {
    transform: scale(1.1);
}

.hero-category .category-more a:focus,
.hero-category .category-more a:hover,
.hero-category .category-title a:focus,
.hero-category .category-title a:hover {
    color: hsla(0, 0%, 100%, .5);
}

.hero-category .category-more:focus,
.hero-category .category-more:hover {
    color: hsla(0, 0%, 100%, .5);
}

.section-product-list {
    padding-bottom: 50px;
    position: relative;
    margin-top: 5rem;
}

.section-product-list {
    margin-top: 0;
}

.section-product-list .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
}

.section-product-list .title-head {
    padding-bottom: 4.285rem;
    font-size: 1.929rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    position: relative;
    z-index: 1;
    font-weight: 700;
}

.section-product-list .title-head:after {
    content: "";
    display: block;
    padding: 3.2px;
    border-radius: 0.7142rem;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 100%;
    width: 1.929rem;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #d11e47, #ef7922);
    background: -webkit-gradient(to right, #d11e47, #ef7922);
    background: -o-linear-gradient(to right, #d11e47, #ef7922);
    top: 3.571rem;
}



.section-product-list .section-head ul.nav {
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.section-product-list .section-head ul.nav li {
    list-style: none;
    position: relative;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 0;
    padding-right: 0;
    font-family: 'Montserrat', sans-serif !important;
    text-align: center;
    font-size: 1rem;
}

.section-product-list .section-head ul.nav li a {
    position: relative;
    display: inline-block;
}

.section-product-list .section-head ul.nav li .white-button {
    font-size: .857rem;
    border-radius: 50px;
    padding: 10px;
}

.section-product-list .section-head ul.nav li a.active {
    color: #fff;
    background: #272727;
    border-color: #272727;
}

.section-product-list .tab-content {
    position: relative;
}


.imgRecContainer {
    overflow: hidden;
}

.recSliderImg {
    height: 100%;
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    object-fit: contain;
    transition: all 0.4s ease 0.2s;
}


.recSliderImg:after {}

.recSlider .recSliderImg:hover {
    transform: scale(1.09);
}

.sliderContainer,
.sliderContainer:hover {
    text-decoration: none !important;
    color: black;
    max-height: 90%;
    min-height: 90%;
    overflow: hidden;
}

.recSlider {
    width: 17%;
    min-height: 150px;
    align-items: center;
    overflow: hidden;
}

.recSlider1 {
    width: 17%;
    height: 487px;
    min-height: 487px;
    align-items: center;
    overflow: hidden;
}

.dPush {
    height: 30px;
    transition: height 0.5s ease;
  }
  
  .collapsed {
    height: 0px;
  }


#sliderHomeRec {

    color: #000;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    position: relative;
    width: 85%;
    align-items: center;
}

#sliderHomeRec span {
    font-family: 'Montserrat', sans-serif !important;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    height: 4px;
    width: 22px;
    background: #999;
    margin-bottom: 15px;
}


.RecproductInfo .rec-brands {
    user-select: none;
    box-sizing: border-box;
    text-decoration: none;
    background-color: transparent;
    color: #000;
    transition: .3s ease-in;
    font-family: 'Montserrat', sans-serif !important;
}

.RecproductInfo div {
    font-size: .9rem;
    font-weight: 700;
}

.RecproductInfo h4 {
    text-shadow: .4px .4px .4px rgba(0, 0, 0);
}

.RecproductInfo h4,
.RecproductInfo .truePrice {
    font-size: 1.1rem;
    font-weight: 800;
}

.special-block {
    display: none;
}

.section-home-footer {
    padding-top: 5%;
}

.section-home-footer .home-why-shop-with-us {
    position: relative;
    z-index: 1;
}

.section-home-footer .home-why-shop-with-us:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #d11e47, #ef7922);
    background: -webkit-gradient(to right, #d11e47, #ef7922);
    background: -o-linear-gradient(to right, #d11e47, #ef7922);
    transform: skew(0deg, 4.7deg);
}

.section-home-footer .home-why-shop-with-us .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.section-home-footer .home-why-shop-with-us .content {
    font-family: CamphorW01-Regular;
    color: #fff;
    padding-top: 2rem;
}

.section-home-footer .home-why-shop-with-us ul {
    padding-left: 0;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.section-home-footer .home-why-shop-with-us ul li {
    list-style: none;
    text-align: center;
    margin-bottom: 1.786em;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.section-home-footer .home-why-shop-with-us .content .image {
    margin-bottom: 1em;
    padding-left: 25px;
    padding-right: 25px;
}

.section-home-footer .home-why-shop-with-us .item-skew.has-animation .image,
.section-home-footer .home-why-shop-with-us .item-skew.has-animation h6,
.section-home-footer .home-why-shop-with-us .item-skew.has-animation p {
    opacity: 0;
    filter: alpha(opacity=0);
}

.section-home-footer .home-why-shop-with-us .item-skew.has-animation.load-animation .image,
.section-home-footer .home-why-shop-with-us .item-skew.has-animation.load-animation h6,
.section-home-footer .home-why-shop-with-us .item-skew.has-animation.load-animation p {
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.section-home-footer .home-why-shop-with-us .item-skew.has-animation.load-animation .image {
    animation-delay: .2s;
}

img[data-src].lazy-loaded {
    background: none;
}

.section-home-footer .home-why-shop-with-us .content .image img {
    height: 60px;
    width: auto;
    max-width: 70px;
}

.section-home-footer .home-why-shop-with-us .content h6 {
    font-size: 1.0714rem;
    margin-bottom: 0.588em;
    font-weight: 900;
}

.section-home-footer .home-why-shop-with-us .content p {
    font-size: 1rem;
    text-align: center;
}

.brand-story {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.785rem;
    position: relative;
    z-index: 1;
    color: #fff;
    width: 100%;
    min-height: 300px;
    overflow: hidden;
}

.brand-story .brand-info {
    position: absolute;
    z-index: 10;
    top: 45%;
    left: 50%;
    width: 100%;
    padding: 0 2.143rem;
    transform: translate(-50%, -50%);
}

.brand-story .brand-info .brand-info-content {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 6px 12px;
}

.brand-story .white-button {
    margin-top: 1.429rem;
    min-width: 9rem;
    font-size: 1rem;
    padding: 0.5rem 0;
    border-radius: 30px;
}

.brand-story.has-animation .brand-title,
.brand-story.has-animation .white-button,
.brand-story.has-animation p {
    opacity: 0;
    filter: alpha(opacity=0);
}

.brand-story.has-animation.load-animation .brand-title,
.brand-story.has-animation.load-animation .white-button,
.brand-story.has-animation.load-animation p {
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.brand-story.has-animation.load-animation .white-button {
    animation-delay: .8s;
}

.brand-story .brand-title {
    font-size: 1.8571rem;
    margin-bottom: 1.142rem;
    font-weight: 900;
}

.brand-story.has-animation.load-animation .brand-title {
    animation-delay: .2s;
}

.editorial-story:before {
    content: "";
    display: block;
    min-height: 9.643em;
    width: 70%;
    position: absolute;
    z-index: 2;
    bottom: -30px;
    z-index: -1;
    background: linear-gradient(90deg,#d11e47,#ef7922);
    background: -webkit-gradient(to right,#d11e47,#ef7922);
    background: -o-linear-gradient(to right,#d11e47,#ef7922);
    transform: skew(0deg,4.7deg);
}

.insta_mar {
    margin:3px
}

.container-fluid.post {
    height: 350px;
}

.container-fluid.post .tab-content.section-content #sliderHomeRec{
    width: 6270px;
}

@keyframes customFadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,0.5em,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
  }

.fade_news_object {
    animation-delay: .2s;
    opacity: 1;
    filter: alpha(opacity=1);
    animation-name: customFadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.editorial-story .bottom:after {
    background: #fff;
    content: "";
    display: block;
    padding: 3px 15px;
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: -15px;
    border-radius: 15px;
}

.editorial-story .editory-info {
    position: absolute;
    z-index: 5;
    left: 1rem;
    color: #fff;
}

.editorial-story .bottom {
    bottom: 2.5rem;
    font-size: 1.65rem;
}

.editorial-story .bottom-right {
    bottom: 1.5rem;
    left: auto;
    right: 1rem;
    text-align: right;
}

.editorial-story .editory-info .white-button {
    display: inline-block;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    box-shadow: 0 2px 7px -2px #333;
    color: #272727;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    min-width: 6em;
    font-size: 1.0714rem;
    padding: 0.357rem 0 0.5rem;
}

.editorial-story .editory-info .white-button:focus, .editorial-story .editory-info .white-button:hover {
    cursor: pointer;
}

.editorial-story .editory-info .white-button:hover {
    color: #fff;
    background-color: #272727;
    border-color: #272727;
}

@media screen and (min-width: 500px) {


    .container-fluid.post {
        height: 457px;
    }
    
    .container-fluid.post .tab-content.section-content #sliderHomeRec{
        width: 8303px;
    }
}

@media screen and (min-width: 650px) {

    .RecproductInfo h4,
    .RecproductInfo .truePrice {
        font-size: 1.4rem;
    }
    .recSlider {
        min-height: 300px;
    }

    .recSlider1 {
        height: 487px;
        min-height: 487px;
    }
}

@media screen and (min-width: 768px) {
    
    .hero-category .item1 a::before {
        top: 130px;
        margin-left: 3px;
    }

    .hero-category .item2 a::before {
        top: 114px;
        margin-left: 3px;
    }
    .insta_mar {
        margin:10px
    }
    .editorial-story .bottom {
        font-size: 1.929rem;
    }
    .editorial-story:before {
        min-height: 14.286em;
    }
    .recSlider {
        min-height: 300px;
    }

    .brand-story .white-button {
        padding: 0.357rem 0 0.5rem;
        font-size: 1.2143rem;
        min-width: 9.286rem;
        margin-top: 2.143rem;
    }

    .brand-story .brand-info {
        width: 70%;
        top: 47%;
    }

    .brand-story .brand-info .brand-info-content {
        padding: 16px 40px;
    }

    .section-home-footer .home-why-shop-with-us ul li {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .section-product-list .section-head ul.nav li .white-button {
        padding: 1.3rem 30px;
        font-size: 1.1429rem;
        line-height: .305em;
    }

    .section-product-list .section-head ul.nav {
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .section-product-list .title-head:after {
        top: 3.571rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .homepage .home-promotion {
        margin-top: 35px;
    }

    .home-promotion .luggage-show {
        min-height: 26.6vw;
    }

    .col-md-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .home-promotion .promotion-info {
        position: absolute;
        top: 50%;
        padding: 1.5em;
        padding-right: 0;
        left: calc(58.33% - 1.5em);
        width: 40%;
        text-align: left;
        margin: 1.4em 0 0;
        transform: translateY(-50%);
        max-height: 250px;
    }

    .home-promotion .promotion-info .title {
        font-size: 1.4286rem;
    }

    .home-promotion .promotion-info .title:after {
        padding: 0.2157rem 2.1428rem;
        margin-top: 0.4em;
        left: 0;
        transform: translateX(0);
        border-radius: 5px;
    }

    .home-promotion .white-button {
        min-width: 5em;
        padding: 1.1rem 30px;
        font-size: 1.1429rem;
        line-height: .305em;
    }

    .hero-category {
        margin-top: 2.1428rem;
    }

    .hero-category .container {
        margin: 0 auto;
    }

    .hero-category .category {
        max-width: 35rem;
        margin: 0 auto;
    }

    #HomepageMain span {
        margin-bottom: 20px;
    }

    .hero-category .item1 a::before {
        top: 125px;
        margin-left: 5px;
    }

    .hero-category .item2 a::before {
        top: 52px;
        margin-left: 5px;
    }
}

@media screen and (min-width: 992px) {
    .editorial-story .editory-info {
        left: 2.571rem;
    }
    .editorial-story .bottom:after {
        bottom: -25px;
        padding: 4px 30px;
    }

    .editorial-story .bottom {
        bottom: 4.786rem;
        font-size: 1.7143rem;
        width: 60%;
    }
    .editorial-story:before {
        bottom: -40px;
        width: 60%;
    }
    .recSlider1 {
        height: 487px;
        min-height: 487px;
    }

    .section-product-list .title-head:after {
        padding: 0.2157rem 2.1428rem;
        margin-top: 0.4em;
        left: 0;
        transform: translateX(0);
        border-radius: 5px;
    }

    .brand-story .brand-title {
        font-size: 3.071rem;
        margin-bottom: 1.428rem;
    }

    .brand-story {
        padding-left: 2.308rem;
        padding-right: 2.308rem;
        text-align: center;
    }

    .section-home-footer .home-why-shop-with-us .content {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    .section-home-footer .home-why-shop-with-us .item-skew {
        padding-bottom: 1.5rem;
    }

    .section-home-footer .home-why-shop-with-us ul {
        position: relative;
        top: -2.5em;
    }

    .section-home-footer .home-why-shop-with-us ul li {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 0;
        padding-left: 2em;
        padding-right: 2em;
    }

    .section-home-footer .home-why-shop-with-us ul li:nth-child(2) {
        top: 2.5em;
    }

    .section-home-footer .home-why-shop-with-us ul li:nth-child(3) {
        top: 4.5em;
    }

    .section-home-footer .home-why-shop-with-us ul li:nth-child(4) {
        top: 6.5em;
    }

    .section-product-list .section-head ul.nav li {
        font-size: 1.1429rem;
        padding-left: 2.087em;
        padding-right: 2.087em;
    }

    .section-product-list .title-head:after {
        left: 0;
        transform: translateX(0);
    }

    .homepage .home-promotion {
        margin-top: 50px;
    }

    .home-promotion .promotion-content {
        text-align: left;
    }

    .home-promotion .container-wide-screen>.row {
        margin-left: 0;
        margin-right: 0;
    }

    .home-promotion img {
        width: 100%;
    }

    .home-promotion .promotion-info {
        padding: 1.943em;
        max-height: 280px;
        /* margin-top: 2em; */
    }

    .home-promotion .white-button {
        min-width: 6em;
    }

    .hero-category {
        margin-top: 1em;
    }

    .hero-category .category {
        max-width: 60rem;
    }

    .hero-category .item1 {
        margin-top: -2.143rem;
    }

    .hero-category .item2 {
        margin-top: 0.357rem;
    }

    .hero-category .image {
        width: 16.3rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 8rem;
        padding-top: 2rem;
        padding-left: 1.0714rem;
    }

    .hero-category .item2 .image {
        border-bottom-left-radius: 8rem;
    }

    .hero-category .category-info {
        width: 16.429rem;
    }

    .hero-category .image a:before {
        width: 15rem;
        height: 15rem;
    }

    .hero-category .image img {
        right: -1.4289rem;
    }

    .hero-category .item1 img {
        right: -0.12857rem;
        bottom: 19px;
        transform: scale(1.03);
    }

    .hero-category .item2 img {
        bottom: 5px;
        right: -1.4286rem;
        transform: scale(1.02);
    }

    .hero-category .item1 a::before {
        top: 85px;
    }

    .hero-category .item2 a::before {
        top: 80px;
    }

    .section-product-list .title-head {
        text-align: left;
        margin-left: 30px;
        font-size: 2.8571rem;
    }
}

@media (min-width: 1200px) {
    

    .brand-story .brand-info {
        width: 50%;
    }
    .section-product-list .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
    }
    .editorial-story .bottom-right {
        bottom: 2em;
        right: 1.5em;
    }
    .editorial-story .editory-info .white-button {
        font-size: 1.2143rem;
    }
    .section-home-footer .home-why-shop-with-us .item-skew {
        margin: 0 auto;
        padding-top: 1rem;
        padding-bottom: 2.5rem;
    }

    #HomepageMain span {
        margin-bottom: 40px;
    }

    .special-block {
        display: block;
    }

    .section-product-list .section-head ul.nav {
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .section-product-list .title-head:after {
        margin-top: 2%;
    }

    .section-product-list .title-head:after {
        height: 0.571rem;
        width: 4.286rem;
        margin-top: 0.7em;
    }

    .home-promotion .container-wide-screen {
        max-width: 1140px;
    }

    .home-promotion .promotion-info {
        left: calc(58.33% - 2.11em);
        max-height: 300px;
    }

    .home-promotion .promotion-info .title {
        font-size: 1.7857rem;
    }

    .home-promotion .promotion-info .title {
        margin-bottom: 1.5em;
    }

    .home-promotion .promotion-info .title:after {
        height: 0.571rem;
        width: 4.286rem;
        margin-top: 0.7em;
    }

    .hero-category {
        padding-top: 2em;
        padding-bottom: 2.5em;
        margin-top: 0.5em;
    }

    .hero-category .category {
        padding-left: 0;
        padding-right: 0;
        max-width: 69.143rem;
    }

    .hero-category .item1 a::before {
        top: 86px;
    }

    .hero-category .item2 a::before {
        top: 78px;
    }

    .section-product-list .container-fluid {
        padding-left: 0;
        padding-right: 0;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1400px) {
    .special-block {
        display: none;
    }
}

@media (min-width: 1600px) {
    .editorial-story:before {
        min-height: 255px;
        bottom: -15px;
    }
    .editorial-story .bottom-right {
        bottom: 2.5em;
        right: 1.875em;
    }
    .editorial-story .editory-info .white-button {
        font-size: 1.4286rem;
    }
    .brand-story .brand-title {
        font-size: 3.7143rem;
        margin-bottom: 2.142rem;
    }

    .brand-story .white-button {
        font-size: 1.4286rem;
        margin-top: 2.857rem;
    }

    .brand-story {
        padding: 0 0 5rem;
    }

    .section-home-footer .home-why-shop-with-us ul li {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .section-home-footer .home-why-shop-with-us .content .image img {
        height: 70px;
        max-width: 80px;
    }

    .section-home-footer .home-why-shop-with-us .content h6 {
        font-size: 1.2857rem;
    }

    .section-home-footer .home-why-shop-with-us .content p {
        font-size: 1.036rem;
    }

    .section-product-list .section-head ul.nav li .white-button {
        padding: 1.5rem 30px;
        font-size: 1.4286rem;
    }

    .section-product-list .section-head ul.nav li {
        font-size: 1.4286rem;
    }

    .section-product-list .section-head ul.nav {
        margin-left: 15rem;
        margin-right: 15rem;
    }

    .section-product-list .title-head:after {
        width: 5.357rem;
        height: 0.714rem;
    }

    .home-promotion {
        padding-left: 11.429em;
        padding-right: 11.429em;
    }

    .home-promotion .container-wide-screen {
        max-width: 1600px;
    }

    .home-promotion .container-wide-screen:first-child {
        padding-top: 4px;
    }

    .home-promotion .container-wide-screen>.row {
        padding-top: 0;
    }

    .home-promotion .promotion-info {
        left: calc(58.33% - 2.653em);
        padding: 3.571em 2.43em;
    }

    .home-promotion .promotion-info .title {
        font-size: 2.1429rem;
    }

    .home-promotion .promotion-info .title:after {
        width: 5.357rem;
        height: 0.714rem;
    }

    .home-promotion .white-button {
        min-width: 6.5em;
        font-size: 1.4286rem;
        padding: 1.3rem 30px;
    }

    .section-product-list {
        padding-left: 11.429em;
        padding-right: 11.429em;
    }

    .section-product-list .container-fluid {
        max-width: 1600px;
    }

    .section-product-list .title-head {
        font-size: 3.7143rem;
    }
}