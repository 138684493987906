
@font-face {
    font-family: 'AT_Font';
    src: url('./font/Manrope-VariableFont_wght.ttf') format('truetype');
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

html {
    scroll-behavior: smooth;
}

iframe#webpack-dev-server-client-overlay {
    display: none;
  }

.display_table p,.display_table li,.display_table td{
    font-weight: 400;
}

.section-header {
    position: fixed;
    /* top: 40px; */
    left: 0;
    right: 0;
    z-index: 998;
    animation: smoothScrollDown .8s forwards;
}

.header-content {
    padding-top: 0.854em;
    padding-bottom: 0.854em;
    position: relative;
    z-index: 10;
    background-color: #fff;
    transition: all .5s ease;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.header-content .navbar-header {
    height: auto;
}

.header-content .main-logo {
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
}

.header-content .at-icon {
    line-height: 35px;
    font-size: 1.7rem;
}

.header-content .main-logo .icon-logo {
    font-size: 2rem;
    display: block;
}

.logoImg {
    max-width: 100%;
    height: auto;
    min-width: 120px;
    color: #fff;
}

.header-content .main-logo a {
    display: block;
}

.section-header .menu-toggleable-left.navbar-toggleable-sm {
    left: auto;
    right: -100%;
    margin-right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    display: block;
    width: 100vw;
    padding-right: 0;
    background: 0 0;
    z-index: 3;
    transition: right .8s cubic-bezier(0, 1, .5, 1);
}

.header-content .navigation-section {
    -ms-flex-align: center;
    align-items: center;
    -ms-align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-justify-content: flex-end;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
}

.section-header .main-menu .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;
}

.section-header .main-menu .navbar {
    color: #272727;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.menu-toggleable-left .menu-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.section-header .main-menu .navbar .menu-group {
    background: #fff;
}

.section-header .main-menu .navbar-nav {
    position: relative;
    padding-top: 55px;
    padding-bottom: 70px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
}

.section-header .main-menu .nav-item {
    position: relative;
}

.section-header .main-menu .nav-item-account,
.section-header .main-menu .nav-top-item {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

.section-header .main-menu .navbar-nav>li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item {
    padding-left: 2.857em;
    padding-right: 2.143em;
    border-bottom: 1px solid #f5f5f5;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}

.section-header .main-menu .navbar .nav-item {
    border-top: 0;
    max-height: 79px;
    min-height: 79px;
}

.header-content .btn-search {
    background: 0 0;
    color: #272727;
    font-size: 23px;
    width: 35px;
    border: 0;
    height: 35px;
    padding: 0;
    margin-left: 1rem;
    transition: .3s ease-in-out;
}

.d-none {
    display: none !important;
}

.navbar-header .minicart {
    margin-left: 0;
    margin-top: 0;
    height: 35px;
}

.navbar-header .icon-store-locator,
.navbar-header .signIn,
.navbar-header .search,
.navbar-header .cart {
    display: inline-block;
    width: 27px;
    border: 0;
    height: 27px;
    position: relative;
    font-size: 1.4286rem;
    color: #272727;
    cursor: pointer;
}

[class^=at-icon] {
    font-family: 'Montserrat', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header-content .at-icon {
    line-height: 35px;
    font-size: 1.7rem;
}

.navbar-header .minicart .minicart-link {
    display: block;
    min-height: 35px;
    min-width: 35px;
    text-align: center;
    position: relative;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #00a1e0;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.navbar-header .minicart .minicart-icon {
    color: #272727;
    line-height: 35px;
    font-size: 1.7rem;
    transition: .3s ease-in-out;
}

a,
body,
html {
    color: #272727;
    font-family: 'Montserrat', sans-serif;
}

a {
    transition: .3s ease-in;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #272727;
}

h1,h2,h3,h4,h5,h6,p {
    font-family: 'Montserrat', sans-serif;
}

.navbar-header .minicart .minicart-quantity {
    position: absolute;
    left: auto;
    right: -10px;
    top: 0;
    display: none;
}

.navbar-header .minicart .minicart-link .minicart-quantity {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    z-index: 1;
    background-color: #c00;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: .714rem;
    min-width: 16px;
    min-height: 16px;
    line-height: 16px;
    border-radius: 50%;
}

.minicart-icon {
    position: relative;
    width: 27px;
    height: 27px;
    top: 4px;
}

img,
picture {
    height: auto;
}

iframe,
img,
picture {
    max-width: 100%;
}

.text-center {
    text-align: center !important;
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.container .breadcrumb {
    border-radius: 0;
    border-bottom: 1px solid #ccc;
}

.breadcrumbs-wrapper .breadcrumb {
    background: 0 0;
    border-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: .786rem;
}

.breadcrumbs-wrapper .breadcrumb li.breadcrumb-item {
    color: #979797;
    padding-right: 0.214em;
}

.breadcrumbs-wrapper .breadcrumb li.breadcrumb-item a {
    color: #272727;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumbs-wrapper .breadcrumb li.breadcrumb-item:after {
    content: ">";
}

.breadcrumbs-wrapper .breadcrumb li.breadcrumb-item:last-child {
    padding-right: 0;
}

.search-results-head .results-head-right {
    font-size: .786rem;
    color: #6b6b6b;
    text-align: right;
}

.product-tile {
    border: 0;
    margin-bottom: 0;
}

.product-tile .image-container {
    position: relative;
    height: 200px;
    overflow: hidden;
    /* Ẩn nội dung tràn ra khỏi container */
}



.product-tile .tile-body {
    padding: 0.625em 0 1.875em;
}

.attribute {
    margin-top: 0.938em;
    margin-right: 25%;
    padding-right: 1em;
}

.product-tile .swatch-circle {
    width: 1.8em;
    height: 1.8em;
    background: #fff;
    border-radius: 0.9em;
    border: 0.063em solid rgba(0, 0, 0, .3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative;
}

.price {
    color: #222;
}



.product-tile .tile-body .price {
    font-size: 1.125em;
    margin-bottom: 0;
}

.price .range,
.price .sales,
.price .starting {
    font-weight: 700;
}

.price .strike-through {
    text-decoration: line-through;
    color: #999;
    margin-right: 0.938rem;
}

.cart-and-ipay {
    text-align: center;
}

.checkbox {
    position: relative;
    padding-right: 15px;
}

.checkbox:after,
.checkbox:before {
    content: "";
    position: absolute;
    top: 50%;
}

.checkbox:after {
    width: 12px;
    height: 12px;
    border: 1px solid #6f6f6f;
    right: 0;
    transform: translateY(-50%);
}

.checkbox:before {
    width: 8px;
    height: 8px;
    right: 2px;
    background-color: #094f8d;
    transition: .3s ease-in;
    transform: translateY(-50%) scale(0);
}

.checkbox.checked:before,
.checkbox.selected:before {
    transform: translateY(-50%) scale(1);
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.nav-link,
.nav-link:hover {
    color: black;
    text-decoration: none;
}

.nav-link::after {
    content: none;
}

.section-header .main-menu .btn-link.collapsed,
.section-header .main-menu .nav-link,
.section-header .main-menu .nav-title {
    padding-top: 1.7em;
    padding-bottom: 1.7em;
    color: #272727;
    background: 0 0;
    font-size: 1rem;
    padding-left: 0;
    display: block;
    line-height: 1;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    transition: none;
}


.menu-collapse.header li {
    border-top: none;
    position: relative;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 0.7em;
    padding-left: 20px;
    text-align: left;
}

.menu-collapse.header>li.dropdown-item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.menu-collapse.header>li.dropdown-item h3 {
    padding-top: 1em;
    padding-bottom: .4em;
    text-transform: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.05rem;
    font-weight: 800;
}

.menu-collapse.header>li.dropdown-item ul {
    box-shadow: none;
    position: static;
    top: 0;
    left: 0;
    display: block;
    padding: 0;
    padding-right: 14px;
    padding-left: 20px;
}

.menu-collapse.header>li.dropdown-item>ul>li {
    text-align: left;
    border: 0;
    margin-top: 0;
    position: relative;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #212529;
}

.menu-collapse.header>li.dropdown-item>ul a {
    padding: 0.3em 0;
    display: block;
    font-family: 'Montserrat', sans-serif;
    white-space: pre-line;
    font-size: 1em;
}

.menu-collapse.header>li.dropdown-item>ul a:hover {
    opacity: 0.3;
}

.text-right {
    text-align: right !important;
}

.modal.show {
    display: block;
}

#miniCartModal {
    z-index: 8888;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-height: 800px;
    min-height: 800px;
    transition: all .5s linear;
    background: white;
    opacity: 1;
    -webkit-animation: fadein 0.5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s;
    /* Firefox < 16 */
    -ms-animation: fadein 0.5s;
    /* Internet Explorer */
    -o-animation: fadein 0.5s;
    /* Opera < 12.1 */
    animation: fadein 0.5s;
}

#miniCartModal .minicart-body-wrapper::-webkit-scrollbar {
    width: 3px;
}

#miniCartModal .minicart-body-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
#miniCartModal .minicart-body-wrapper::-webkit-scrollbar-thumb {
    background: #000;
}

/* Handle on hover */
#miniCartModal .minicart-body-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.minicart-container {
    background-color: #fff;
    width: calc(100% - 40px);
    margin-left: auto;
    max-height: 100%;
    overflow-x: hidden;
    padding-top: 10px;
}

#miniCartModal .minicart-header {
    position: absolute;
    width: calc(100vw - 70px);
    padding: 0 10px;
    z-index: 5;
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
}

#miniCartModal .minicart-header .close-minicart {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background: 0 0;
    color: #c00;
}

#miniCartModal .minicart-header .minicart-title {
    font-size: 1.1429rem;
    padding: 12px 0;
}

#miniCartModal .minicart-body-wrapper {
    padding-top: 45px;
    max-height: 270px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 12px;
    padding-left: 30px;
}

#miniCartModal .minicart-container .product-info {
    font-size: .857rem;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    position: relative;
    box-shadow: none;
}

#miniCartModal .minicart-container .product-info .promotion-price {
    text-decoration: line-through;
}

#miniCartModal .minicart-container .product-info .product-card-footer {
    position: absolute;
    right: 0;
    top: calc(50% - 10px)
}

#miniCartModal .minicart-container .product-info .product-card-footer.top-right {
    position: absolute;
    right: 0;
    top: calc(20px)
}

#miniCartModal .minicart-container .product-info .product-card-footer .container-applied-promotion {
    display: block;
    font-size: 1rem;
}

#miniCartModal .minicart-container .product-info .product-card-footer .container-applied-promotion .line-item-final-price {
    text-align: right;
    color: #c00;
}

#miniCartModal .minicart-container .product-info .product-card-footer .container-applied-promotion .line-item-final-price {
    text-align: right;
    color: #000;
}

#miniCartModal .minicart-container .product-info .item-image {
    margin-right: 10px;
    height: auto;
    width: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-height: 98px;
}

#miniCartModal .minicart-container .product-info .item-image img {
    max-height: none;
    margin: 0;
    width: 65px;
}

#miniCartModal .minicart-container .product-info .item-attributes {
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    max-width: 40%;
}

#miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes {
    margin-bottom: 0.7rem;
}

#miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes.collection-name {
    font-size: .857rem;
}

#miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes.product-color {
    font-size: .92857em;
}

#miniCartModal .minicart-container .minicart-footer {
    min-height: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#miniCartModal .minicart-container .minicart-totals .minicart-total,
#miniCartModal .minicart-container .minicart-totals .promotion-information,
#miniCartModal .minicart-container .minicart-totals .shipping-information [data-shipping-id] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #dfdfdf;
    margin: 0;
}

#miniCartModal .minicart-container .minicart-totals .minicart-total {
    margin-bottom: 20px;
}

#miniCartModal .minicart-container .minicart-totals .minicart-total span {
    font-size: 1rem;
}

#miniCartModal .minicart-container .minicart-totals .minicart-total span.grand-total {
    font-weight: 600;
}

#miniCartModal .minicart-container .minicart-footer .footer-action {
    -ms-flex-pack: center;
    justify-content: center;
}

#miniCartModal .minicart-container .minicart-footer .view-cart .btn-view-cart {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.0714rem;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in, background-color .2s ease-in, border-color .2s ease-in, box-shadow .2s ease-in;
    box-shadow: 0 2px 7px -2px #333;
    color: #272727;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    padding: 10px;
    max-width: 160px;
    min-width: 160px;
}

#miniCartModal .minicart-container .minicart-footer .view-cart .btn-view-cart:hover {

    color: #fff;
    background-color: #272727;
}

#miniCartModal .minicart-container .minicart-footer {
    padding-right: 30px;
    padding-left: 30px;
}

.site-search .searchform {
    width: 87%;
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom: 70px;
    position: relative;
}

.site-search .form-control {
    height: 40px;
}

.site-search input.form-control {
    margin-top: 40px;
    letter-spacing: 2.5px;
    border: solid #737373;
    border-width: 0 0 2px;
    background-color: transparent;
    font: 0.89rem Gotham-Bold;
    box-shadow: none !important;
    padding: 0.325em 3em;
    width: 100%;
    text-transform: uppercase;
    border-radius: 0;
}


.navbar-header .account-header .user {
    margin-left: 0;
    margin-top: 0;
    line-height: 1;
    position: relative;
}

.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
}

.popover,
.popover .arrow {
    position: absolute;
    display: block;
}

.navbar-header .popover {
    border-radius: 7px;
    box-shadow: 0 2px 7px -4px #333;
    transition: .3s ease-in;
    position: absolute;
    top: 3.5715em;
    left: -6.786em;
    padding: 0;
    z-index: 9999;
    max-width: none;
    font-size: 1rem;
    border: 1px solid #d9d9d9;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    z-index: 1;
    display: block;
}

.navbar-header .popover:before {
    top: -20px;
    border: 10px solid transparent;
    border-bottom-color: #d9d9d9;
}

.navbar-header .popover::after {
    top: -16px;
    z-index: 3;
    border: 8px solid transparent;
    border-bottom-color: white;
}

.navbar-header .popover:after,
.navbar-header .popover:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.navbar-header .account-header .user ul {
    padding-left: 0;
    margin-bottom: 0;
}

.navbar-header .account-header .user ul li {
    list-style: none;
    padding: 0;
    border-bottom: 2px solid #f3f3f3;
}

.navbar-header .account-header .user ul li.name-item {}

.navbar-header .account-header .user ul li:last-child {
    border-bottom: 0;
}

.navbar-header .popover svg {
    margin: 0 15px;
    font-size: 1.5rem;
    line-height: 35px;
}

.navbar-header .popover a {
    display: block;
    font-size: 1rem;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding: 0.6em 0.8em;
    min-width: 16em;
    position: relative;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group {
    position: relative;
    margin-bottom: 2.2rem;
    font-size: 1em;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control,
.form-control:focus {
    box-shadow: none;
}

.form-group .form-control {
    padding: 0.2857em 0.1429em;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    border-radius: 0;
    background-color: transparent;
    height: 2em;
}

.form-group input.form-control {
    padding-bottom: 1em;
}

.btn-block {
    display: block;
    width: 100%;
}

.responsive-menu {
    display: block;
}


.col-md-6 {

    flex: 0 0 100%;
    width: 100%;
}


.text-center {
    text-align: center !important;
}

.map-direction.row #map {
    min-height: 600px;
    height: 100%;
}

.map-direction.row .list-store {
    height: 600px;
    overflow-y: auto;
}

.list-store .title-store {
    color: #272727;
    padding-bottom: 8px;
    font-weight: 700;
}

.list-store .title-store span {
    color: #3d3d3d;
    font-size: 15px;
    text-transform: initial;
}

ul.no-bullets {
    padding-left: 0;
}

.list-store li {
    padding: 10px 5px;
    border: solid 1px #f9f9f9;
    cursor: pointer;
    list-style-type: none;
}

.list-store li p {
    margin: 0 0 5px 0;
}

.map_container iframe {
    height: 100%;
    width: 100%;
}

.attr-item {
    /* margin-right: 8px; */
    position: relative;
    vertical-align: bottom;
}

.col-lg-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.notForMobile {
    display: none;
}

.global-noti {
    width: 100%;
    background: black;
    background: linear-gradient(90deg, #d31e47, #f37a21);
    background: -webkit-gradient(to right, #d31e47, #f37a21);
    background: -o-linear-gradient(to right, #d31e47, #f37a21);
    color: white;
    height: 25px;
    display: flex;
    align-items: center;
    /* Căn giữa theo chiều dọc */
    justify-content: center;
    /* Căn giữa theo chiều ngang */
    font-size: 11px;
}

.row.search-result-content {
    min-height: 400px;
}

.oneLineText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand-story img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 300px;
}

.blank-slate {
    padding: 1.5em;
    /* text-align: center; */
}

.non-mobile {
    display: block;
}

.hrv-discount-choose-coupons {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #338dbc;
}

.hrv-discount-choose-coupons > div:first-child {
    flex: 0 0 100%;
    margin-bottom: 10px;
}

.hrv-discount-choose-coupons {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #338dbc;
}

.hrv-discount-choose-coupons #list_short_coupon {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.hrv-discount-choose-coupons #list_short_coupon > span {
    overflow: hidden;
    padding: 6px 0;
    position: relative;
    margin-bottom: 5px;
}

.hrv-discount-choose-coupons #list_short_coupon > span:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #338dbc;
    background: #fff;
    z-index: 1;
    left: -7px;
    top: 50%;
    position: absolute;
    border-radius: 50%;
    transform: translateY(-50%);
}

.hrv-discount-choose-coupons #list_short_coupon > span:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #338dbc;
    background: #fff;
    z-index: 1;
    right: -7px;
    top: 50%;
    position: absolute;
    border-radius: 50%;
    transform: translateY(-50%);
}

.hrv-discount-choose-coupons #list_short_coupon > span span {
    border: 1px solid #338dbc;
    padding: 5px 10px;
    border-radius: 3px;
    background: #fff;
    font-weight: 700;
    color: #338dbc;
}

.hrv-coupons-popup-site-overlay {
    background: #CFCFCF;
    position: fixed;
    opacity: 0.6 !important;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 880;
    visibility: hidden;
}

.hrv-coupons-popup {
    width: 375px;
    transition: opacity 0.5s ease-out;
    padding: 0;
    opacity: 1;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(33 33 33 / 20%);
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 70vh;
    min-height: 500px;
    z-index: 1234;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.active-popup {
    opacity: 1;
    visibility: visible;
}

.hrv-title-coupons-popup {
    display: flex;
    padding: 19px 16px;
    position: relative;
    box-shadow: inset 0px -1px 0px #eeeeee;
}

.hrv-title-coupons-popup p {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    padding-right: 60px;
    color: #424242;
}

.hrv-title-coupons-popup div {
    width: 60px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.hrv-title-coupons-popup div svg {
    float: right;
    cursor: pointer;
}

.hrv-content-coupons-code {
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100% - 82px);
    padding: 11px 12.5px;
}

h3.coupon_heading {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 0 3.5px;
    width: 100%;
    color: #424242;
}

.hrv-content-coupons-code .coupon_item {
    position: relative;
    background: #fff;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .15));
    padding: 10px 16px;
    display: flex;
    min-height: 80px;
    border-radius: 5px;
    margin: 5px 0px 15px 2px;
}

.hrv-content-coupons-code .coupon_icon {
    display: flex;
    width: 100%;
    align-items: center;
}

.hrv-content-coupons-code .coupon_body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    position: relative;
}

.hrv-content-coupons-code .coupon_body .coupon_head {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}


.hrv-content-coupons-code .coupon_icon .icon-svg {
    width: 37px;
    flex: 0 0 auto;
    margin-right: 10px;
    text-align: center;
}

.hrv-content-coupons-code .coupon_icon .icon-svg svg {
    width: 100%;
}

.hrv-content-coupons-code .coupon_item h3.coupon_title {
    font-size: 16px;
    width: calc(100% - 47px);
    margin: 0.25em 0 5px;
}

.hrv-content-coupons-code .coupon_item h3.coupon_title span {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
}

.hrv-content-coupons-code .coupon_item .coupon_exp {
    max-width: calc(100% - 75px);
    line-height: 20px;
    margin-top: 0px;
    font-size: 14px;
}

.hrv-content-coupons-code .coupon_item .coupon_desc {
    display: none;
    position: relative;
    z-index: 2;
}


.hrv-content-coupons-code .coupon_item div[class*="coupon_desc"] {
    width: 100%;
    font-size: 14px;
    color: #212121;
}

.hrv-content-coupons-code .coupon_item .coupon_desc ul, .hrv-content-coupons-code .coupon_item .coupon_desc_short ul {
    list-style: initial;
    list-style-position: outside;
    padding-left: 18px;
}

.hrv-content-coupons-code .coupon_item .coupon_actions {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.hrv-content-coupons-code .coupon_item .coupon_more {
    cursor: pointer;
    color: #338dbc;
    margin-top: 0px;
    position: relative;
    z-index: 2;
    font-size: 14px;
}

.btn_apply_line_coupon {
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;
    padding: 1.4em 1.7em;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    position: relative;
    background: #338dbc;
    color: white;
}



.btn_apply_line_coupon {
    height: 32px;
    padding: 5px 10px !important;
    width: auto !important;
    background: #338dbc;
    border: none;
}

.btn_apply_line_coupon:hover {
    color: white;
    filter: brightness(1.2);
}

.zalo-share-button iframe {
    width: 100%;
    max-width: 1960px;
    z-index: 9999;
}

.shop-by-color-product-type {
    margin-top: 35px;
    text-align: center;
    padding: 0 15px;
    position: relative;
    z-index: 1;
    animation-fill-mode: both;
    margin: 25px auto 35px;
    .x {
        color: black
    }
    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .product-type-title {
        font-weight: 700;
        text-align: center;
        align-items: center;
        letter-spacing: .5px;
    }
    .col-xl-9 .row {
        margin: 0 -3px;
    }
    .col-xl-9 .row .col-3 {
        padding: 0 3px;
    }
    .col-xl-9 .row .col-3 a {
        text-align: center;
        display: block;
        background-color: #fff;
        line-height: 30px;
        border: 0;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, .3);
        border-radius: 15px;
        margin-top: 15px;
        font-weight: 700;
        font-size: 11px;
        position: relative;
        overflow: hidden;
        transition: all .5s ease;
        color: #000;
    }
    .col-xl-9 .row .col-3 a .refiment-text {
        background: transparent;
        position: relative;
        z-index: 2;
    }
    .col-xl-9 .row .col-3 a .refiment-bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: transparent;
        background: #ffffff;
        transition: all .5s ease;
    }
}

.shop-by-color-product-type .col-xl-9 .row .col-3 a.selected .refiment-bg {
    background: rgb(245, 245, 220) !important;
  }
  
  .shop-by-color-product-type .col-xl-9 .row .col-3 a:hover .refiment-bg {
    background: rgb(245, 245, 220) !important;
  }



.shop-by-color-banner {
    position: relative;
    z-index: 2;
    background: #fff;
}
.cat-banner-content {
    padding: 25px 35px 15px;
    text-align: center;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, .3);
}
.cat-banner-content .description {
    padding-top: 15px;
}


@media (max-width: 767.98px) {
    .hrv-content-coupons-code .coupon_icon {
        padding: 10px 0;
    }
    .hrv-content-coupons-code .coupon_item {
        padding: 5px 12.5px;
        width: calc(100% - 25px);
        margin: 5px 0 15px;
    }
    .hrv-content-coupons-code .coupon_icon > div {
        flex: 0 0 auto;
    }
    .hrv-coupons-popup {
        width: 100%;
        top: unset;
        bottom: 0;
        left: 0;
        height: 80vh;
        min-height: unset;
        max-height: unset;
        border-radius: 8px 8px 0 0;
        transform: translate(0, 0);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-transition: transform 0.35s ease, bottom 0.25s ease, visibility 0s;
        transition: transform 0.3;
    }
    .hrv-coupons-popup.active-popup {
        max-height: calc(100% - 60px);
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
    .menu-toggleable-left .bg-inverse {
        background-color: #fff !important;
        color: #222;
    }

    .navbar-header .minicart {
        margin-left: 1rem;
    }
    
    .non-mobile {
        display: none;
    }
    
    .cus-input select {
        padding : 1.8em 1.7em 0.5em 0.5em
    }


}


@media (min-width: 576px) {
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .product-tile .image-container {
        height: 220px;
    }
}

@media (min-width: 768px) {
    .shop-by-color-product-type {
        width: 690px;
        margin: 25px auto 35px;
    }
    .brand-story img {
        width: auto;
        max-width: 100%;
    }
    .global-noti {
        display: flex;
        font-size: 14px;
    }

    .site-search input.form-control {
        margin-top: 20px;
        font: 1.2857rem Gotham-Book;
        letter-spacing: 7px;
        padding-bottom: 0.825em;
    }

    .product-tile .image-container {
        height: 220px;
    }

    .site-search input.search-field {
        background: 0 0 !important;
        border-bottom: 1px solid #f3f3f3;
        padding-left: 2rem;
        color: #272727;
        border-radius: 0;
    }

    .site-search .searchform {
        overflow: hidden;
        height: auto;
        width: 75%;
        padding-bottom: 2em;
    }

    .site-search {
        width: 100%;
        border-top: 1px solid #f5f5f5;
    }

    #miniCartModal .minicart-container .minicart-footer .view-cart {
        text-align: center;
    }

    #miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes.product-color {
        font-size: 1.07143em;
    }

    #miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes.collection-name:first-child {
        margin-top: 0;
    }

    #miniCartModal .minicart-container .product-info .item-attributes {
        max-width: 50%;
    }

    #miniCartModal .minicart-container .product-info .item-image img {
        width: 80px;
    }

    #miniCartModal .minicart-container .product-info .item-image {
        width: 100px;
    }

    #miniCartModal .minicart-body-wrapper {
        height: 200px;
    }

    #miniCartModal .minicart-header {
        width: 470px;
    }

    #miniCartModal .minicart-container {
        width: 100%;
        max-width: 500px;
    }

    .navbar.bg-inverse .navbar-nav .nav-link {
        padding: 0.8rem;
        white-space: nowrap;
    }

    .section-header .main-menu .btn-link.collapsed,
    .section-header .main-menu .nav-link,
    .section-header .main-menu .nav-title {
        padding-top: 1.7em;
        padding-bottom: 1.7em;
    }

    .section-header .main-menu .container-fluid {
        max-width: 340px;
    }

    .section-header .main-menu .navbar {
        display: block;
    }

    .section-header .main-menu .navbar .menu-group {
        -ms-flex-pack: center;
        justify-content: center;
        display: -ms-flexbox;
        display: flex;
        display: -ms-flex;
    }

    .navbar.bg-inverse .navbar-nav .nav-item+.nav-item {
        margin-left: 0;
    }

    .header-content .navigation-section .btn-search {
        background: 0 0;
    }

    .col-md-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .container {
        max-width: 720px;
    }

    #miniCartModal {
        position: absolute;
        top: 100px;
        bottom: auto;
        max-height: 360px;
        min-height: 360px;
        width: 80%;
        margin-right: 30px;
        max-width: 500px;
        border-top: none;
        margin-left: auto;
        min-width: 480px;
    }
}

@media (min-width: 992px) {
    .shop-by-color-product-type {
        margin-top: 10px;
    }

    .global-noti {
        font-size: 17px;
    }
    .brand-story img {
        width: 100%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .search-xs-section .site-search .searchform {
        width: 100%;
    }

    .site-search {
        padding-left: 4em;
        padding-right: 4em;
    }

    #miniCartModal .minicart-body-wrapper {}

    .header-content .navbar-header {
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .header-content .at-icon {
        font-size: 1.4143rem;
    }

    .col-lg-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .header-content .store-location-block {
        margin-left: 1rem;
    }

    .navbar-header .account-header {
        margin-left: 1.5rem;
    }

    .header-content .btn-search {
        margin-left: 0.8rem;
    }

    .navbar-header .minicart {
        margin-left: 0.8rem;
        position: relative;
    }

    .header-content .at-icon {
        font-size: 1.4143rem;
    }

    .navbar-header .minicart .minicart-icon {
        font-size: 1.4143rem;
    }

    .container {
        max-width: 940px;
    }

    .d-lg-block {
        display: block !important;
    }

    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 1200px) {
    
    .shop-by-color-product-type {
        padding: 0;
        width: 1000px;
        margin: 25px auto 35px;
        .x {
            color: black
        }
        .product-type-title {
            line-height: 50px;
            font-size: 16px;
        }
        .col-xl-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xl-9 {
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-xl-9 .row {
            margin: 0 -10px;
        }
        .col-xl-9 .row .col-3 {
            padding: 0 10px;
        }
        .col-xl-9 .row .col-3 a {
            margin-top: 0;
            line-height: 50px;
            border-radius: 25px;
            font-size: 17px;
        }
    }
    .col-lg-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .product-tile .image-container {
        /* height: 270px; */
    }

    .notForMobile {
        display: block;
    }

    .responsive-menu {
        display: none;
    }

    #miniCartModal .minicart-container .minicart-totals .minicart-total span {
        font-size: 1.1429rem;
    }

    #miniCartModal .minicart-container .minicart-footer {
        min-height: auto;
        padding-left: 0;
    }

    #miniCartModal .minicart-container .product-info .item-attributes .line-item-attributes.collection-name {
        font-size: 1rem;
    }

    #miniCartModal .minicart-body-wrapper {
        padding-top: 10px;
        min-height: 400px;
        padding-left: 0px;
    }

    #miniCartModal .minicart-container .product-info .item-image img {
        min-height: 120px;
    }

    #miniCartModal .minicart-container .product-info .item-image {
        width: 120px;
        min-height: 120px;
    }

    #miniCartModal .minicart-container {
        margin-left: 30px;
        width: calc(100% - 30px);
        /* border: 1px solid #dfdfdf; */
        border-top: 0;
        height: 550px;
        padding-top: 0;
        padding-right: 0;
    }

    #miniCartModal {
        position: absolute;
        top: auto;
        bottom: auto;
        max-height: 550px;
        min-height: 550px;
        width: 30%;
        margin-right: 30px;
        max-width: 500px;
        border-top: none;
        margin-left: auto;
        min-width: 480px;
    }

    .menu-collapse.header>li.dropdown-item {
        padding-top: 15px;
        padding-right: 15px;
        width: 14em;
    }

    .menu-collapse.header li {
        border-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item .nav-link {
        padding: 0 0.2rem;
        font-size: 1.05rem;
        font-family: 'Montserrat', sans-serif;
    }

    .section-header .main-menu .navbar .nav-item>.nav-link {
        position: relative;
    }

    .section-header .main-menu .btn-link.collapsed,
    .section-header .main-menu .nav-link,
    .section-header .main-menu .nav-title {
        font-size: 1rem;
    }

    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        content: "";
        height: 3px;
        position: absolute;
        left: 50%;
        top: 200%;
        z-index: 332;
        width: 0;
        margin-top: 1em;
        transition: .3s ease-in;
        transform: translateX(-50%);
    }

    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        background: linear-gradient(90deg, #c00, #ef7922);
        background: -webkit-gradient(to right, #c00, #ef7922);
        background: -o-linear-gradient(to right, #c00, #ef7922);
    }

    .section-header.logo-red .main-menu .navbar .nav-item>.nav-link:before {
        background: linear-gradient(90deg, #d31e47, #f37a21);
        background: -webkit-gradient(to right, #d31e47, #f37a21);
        background: -o-linear-gradient(to right, #d31e47, #f37a21);
    }

    .section-header.logo-orange .main-menu .navbar .nav-item>.nav-link:before {
        background: linear-gradient(90deg, #f37a21, #d31e47);
        background: -webkit-gradient(to right, #f37a21, #d31e47);
        background: -o-linear-gradient(to right, #f37a21, #d31e47);
    }

    .section-header.logo-blue .main-menu .navbar .nav-item>.nav-link:before {
        background: linear-gradient(90deg, #3393d0, #6ac6b1);
        background: -webkit-gradient(to right, #3393d0, #6ac6b1);
        background: -o-linear-gradient(to right, #3393d0, #6ac6b1);
    }

    .section-header.logo-green .main-menu .navbar .nav-item>.nav-link:before {
        background: linear-gradient(90deg, #81c341, #ffc113);
        background: -webkit-gradient(to right, #81c341, #ffc113);
        background: -o-linear-gradient(to right, #81c341, #ffc113);
    }

    .section-header .main-menu .navbar .nav-item>.nav-link:hover:before {
        width: 100%;
    }

    .breadcrumbs-wrapper .breadcrumb {
        font-size: .857rem;
    }

    .header-content {
        padding-left: 4em;
        padding-right: 4em;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #f2f2f2;
    }

    .section-header .header-content-inner,
    .section-header .main-menu,
    .section-header .navbar,
    .section-header .navbar-nav {
        position: static;
    }

    .header-content .main-logo .icon-logo {
        font-size: 2.45rem;
    }

    .section-header .main-menu {
        margin-top: 0;
        border: 0;
        padding-left: 0;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm {
        position: static;
        right: auto;
        width: auto;
    }

    .section-header .main-menu .container-fluid {
        max-width: none;
    }

    .section-header .main-menu .navbar {
        overflow: visible;
    }

    .section-header .main-menu .navbar-nav {
        padding-top: 0;
        padding-bottom: 0;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        max-width: none;
        position: static;
    }

    .section-header .main-menu .nav-top-item {
        padding: 0 0.5em;
    }

    .section-header .main-menu .navbar-nav>li {
        -ms-flex: none;
        flex: none;
        padding-left: 0;
        padding-right: 0;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item {
        border-bottom: 0;
        padding: 1.75em 0.95em;
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        display: -ms-flex;
    }

    .section-header .main-menu .navbar .nav-item {
        position: static;
    }

    .d-xl-block {
        display: block !important;
    }

    .header-content .store-location-block {
        margin-left: 1.5rem;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .shop-by-color-product-type {
        margin-top: 15px;
    }

    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        top: 250%;
    }

    .site-search input.search-field {
        font-size: 1.1429rem;
        height: 60px;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item .nav-link {
        font-size: 1.05rem;
        font-weight: 800;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item {
        padding: 1.25em 1.6em;
    }

    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        top: 200%;
    }
}

@media (min-width: 1500px) {
    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        top: 194%;
    }
}

@media (min-width: 1560px) {
    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        top: 184%;
    }
}

@media (min-width: 1600px) {
    .section-header .main-menu .navbar .nav-item>.nav-link:before {
        top: 180%;
    }

    .menu-collapse.header>li.dropdown-item {
        padding-top: 20px;
        padding-right: 20px;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item .nav-link {
        font-size: 1.1429rem;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item {
        padding-left: 1.7em;
        padding-right: 1.7em;
    }

    .header-content {
        padding-left: 4.5em;
        padding-right: 4.5em;
    }

    .col-xxl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .header-content .main-logo {
        max-width: 10%;
    }

    .col-xxl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .section-header .main-menu {
        margin-left: 4%;
        padding-left: 15px;
    }

    .header-content .navigation-section {
        max-width: 20.5%;
        padding-left: 0;
    }

    .section-header .menu-toggleable-left.navbar-toggleable-sm .nav-item {
        padding-left: 1.7em;
        padding-right: 1.7em;
    }

    .header-content .store-location-block {
        margin-left: 2rem;
    }

    .navbar-header .account-header {
        margin-left: 2rem;
    }

    .header-content .btn-search {
        margin-left: 1.2rem;
    }

    .header-content .at-icon {
        font-size: 1.6429rem;
    }

    .navbar-header .minicart .minicart-icon {
        font-size: 1.6429rem;
    }

    .container {
        max-width: 1340px;
    }
}

.quantityHolder {
    width: 50px;
    padding: 10px;
    display: inline-block;
    text-align: center;
}




@keyframes fadein {
    from {
        opacity: 0;
        max-height: 0px;
        min-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 500px;
        min-height: 500px;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
        max-height: 0px;
        min-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 500px;
        min-height: 500px;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
        max-height: 0px;
        min-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 500px;
        min-height: 500px;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
        max-height: 0px;
        min-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 500px;
        min-height: 500px;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
        max-height: 0px;
        min-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 500px;
        min-height: 500px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


@media (min-width: 1200px) and (max-width: 1600px) {
    .shop-by-color-product-type {
        width: 1000px;
    }
}