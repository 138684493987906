 
  
  /* Điều chỉnh thanh cuộn trên Chrome, Edge, và Safari */
  /* thanh tổng */
  .div-with-custom-scrollbar::-webkit-scrollbar {
    width: 4px; /* Đặt chiều rộng thanh cuộn thành mỏng */
  }
  

  /* thanh roll */
  .div-with-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 5px;
  }
  
  

.search-results {
    background-color: #f6f7f9;
}

.row.search-results-head .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.search-results-head {
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}

.product-grid-wrapper {
    padding-left: 12px;
    padding-right: 12px;
}

.product-grid {
    padding-top: 12px;
    margin-left: -6px;
    margin-right: -6px;
}

.refinement-wrapper .block {
    border-top: 1px solid #6b6b6b;
}

.refinement-wrapper.non-border .block {
    border : none;
    margin-bottom: 15px;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.refinement-wrapper .refinements .card-body {
    display: block;
    padding: 0;
}

.refinement-wrapper .block-sort ul {
    margin-bottom: 0;
    padding-left: 0;
    font-size: 1rem;
}

.refinement-wrapper .block-sort ul li {
    list-style: none;
    position: relative;
    /* padding-top: 0.857em; */
    padding-bottom: 0.857em;
    cursor: pointer;
}

.refinement-wrapper .block-sort ul li.selected:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    right: 3.214em;
    top: 30%;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: .3s ease-in;
}

.refinement-wrapper .block-sort ul li.selected:after {
    background: linear-gradient(90deg, #ff6600, #ff0077);
}


.block-head .collapse.show {
    padding-left: 10px;
}

.refinement-wrapper .refinements .content {
    display: block;
    padding: 0;
    margin-bottom: 0;
}

.refinement-wrapper .refinements ul.refinement-colour {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 5em;
}

.refinement-wrapper .refinements .content li {
    list-style: none;
    padding-left: 5em;
    padding-right: 0;
    border-top: 2px solid #f5f5f5;
    font-size: 1rem;
}

.refinement-wrapper .refinements ul.refinement-colour li {
    -ms-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
    display: inline-block;
    border: 0;
    padding: 0;
    margin-bottom: 0.9em;
}

.refinement-wrapper .refinements ul.refinement-colour li a {
    display: block;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle {
    display: inline-block;
    border: 0.063em solid transparent;
    width: 1.9em;
    height: 1.9em;
    display: block;
    position: relative;
    background: 0 0;
    padding: 0;
    color: transparent;
    text-indent: -969rem;
    border-radius: 50%;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle:before {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 50%;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0140:before {
    background-color: beige;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0010:before {
    background-color: #000;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0020:before {
    background-color: #3393d0;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0030:before {
    background-color: #81c341;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0040:before {
    background-color: #8f979d;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0130:before {
    background-color: #6ac6b1;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0070:before {
    background-color: #640243;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0090:before {
    background-color: #f70404;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0120:before {
    background-color: #fff;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0110:before {
    background-color: #ffc012;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0150:before {
    background-color: #181e51;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0160:before {
    background-color: #eb781f;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0170:before {
    background-color: #e00c80;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0180:before {
    background-color: #C0C0C0;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0200:before {
    background-color: #A52A2A;
}


.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle-0120 {
    border-color: #d1d1d1;
}

.refinement-wrapper .refinements ul.refinement-colour li .swatch-circle.selected {
    border-color: #272727;
    border-width: 0.143rem;
}

.grid-footer {
    text-align: center;
    margin-top: 1.786em;
    margin-bottom: 1.786em;
}   

.product-tile-col {
    overflow: visible;
}

.product-grid .product-tile-col {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
    min-height: 340px;
}

.product-grid .product {
    background-color: #fff;
    height: 100%;
}

.product-tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    text-align: center;
    padding-left: 0.286em;
    padding-right: 0.286em;
}

.product-tile .image-container {
    text-align: center;
    padding-top: 1.429em;
}

.product-tile .image-container a {
    display: block;
}

.product-tile .image-container .tile-image {
    /* max-width: 70%; */
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.product-tile .image-container a .tile-image {
    
    width: 100%;
    height: 200px;
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: 100%;
}

.product-tile .image-container a .tile-image:hover {
    transform: scale(1.1);
}

.product-tile .tile-body {
    padding-top: 10px;
    padding-bottom: 0;
}

.product-grid .product-tile .tile-body {
    margin-bottom: 100px;
}

.product-tile .tile-body .swatch-row {
    min-height: 64px;
}

.product-tile .attribute {
    margin-top: 0;
    margin-right: 0;
    padding-top: 0.714em;
    padding-right: 0;
    text-align: center;
}

.product-tile .color-quantity {
    font-size: .857rem;
    color: #6b6b6b;
}

.owl-carousel {
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}

.product-tile .color-swatchs {
    text-align: center;
    margin-right: 0;
    padding: 0.514em 0.8em;
    min-height: 40px;
}

.product-tile .attribute .owl-carousel {
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.product-tile .attribute .owl-carousel, .product-tile .attribute .owl-carousel .owl-stage {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -ms-flex-align: center;
    align-items: center;
}

.product-tile .attribute .attr-item {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
}

.product-tile .color-swatchs .attr-item {
    padding-left: 4px;
    padding-right: 4px;
}

.product-tile .color-swatchs .attr-item a {
    display: block;
    width: 16px;
    height: 16px;
}

.product-tile .color-swatchs .attr-item.selected .attr-selector {
    width: 25px;
    height: 25px;
}

.product-tile .color-swatchs .attr-item a .swatch-circle {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    border-radius: 50%;
}

.attr-item .attr-selector {
    cursor: pointer;
}

.product-tile .size-selection {
    font-size: .857rem;
}

.product-tile .attribute .owl-carousel {
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.product-tile .size-selection .attr-item a {
    display: block;
    padding: 0.363em 0.5855em;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
    font-size: .786rem;
    border-radius: 15px;
    box-shadow: 0 1px 3px -1px #272727;
}

.product-tile .size-selection .attr-item.selected a, .product-tile .size-selection .attr-item:hover a {
    color: #fff;
    background-color: #272727;
    border-color: #272727;
}

.product-tile .tile-body .tile-attribute[data-attr=productSize] {
    margin-bottom: 0.5rem;
}

.product-tile .product-collection {
    font-size: 1.0714rem;
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif !important;
}

.product-tile .product-collection h4 {
    margin-bottom: 0;
    font-size: 1.0714rem;
}

.product-tile .pdp-link {
    font-size: .786rem;
    margin-bottom: 5px;
    color: #6b6b6b;
}

.product-tile .tile-body .pdp-link {
    line-height: 1.2;
}

.product-tile .pdp-link a {
    color: #6b6b6b;
}

.product-tile .pdp-link a {
    text-transform: uppercase;
}

.product-tile .tile-body .pdp-link a {
    font-size: 1em;
    text-decoration: none;
}

.product-tile .tile-body .price {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    margin-top: 0.667em;
}

.product-tile .tile-body .price .price-inner {
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    white-space: nowrap;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-tile .tile-body .sales.promotion-price {
    color: #d11e47;
}

.product-tile .tile-body .price .strike-through {
    margin-right: 0;
    color: #272727;
    font-size: .857rem;
    position: relative;
    vertical-align: baseline;
    margin-left: 0.2em;
    padding-top: 0.23em;
}

.product-tile .tile-bottom {
    position: relative;
}

.product-tile .tile-bottom .tile-actions-color-attributes {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 1rem;
}

.product-tile .tile-actions {
    padding-left: 0.357em;
    padding-right: 0.357em;
}

.product-tile .btn-go-details {
    display: inline-block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    box-shadow: 0 2px 7px -2px #333;
    color: #272727;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    display: block;
    font-size: .857rem;
    padding: 0.667em;
}

.product-tile .cart-and-ipay {
    padding-bottom: 0;
}

.product-tile .add-to-cart, .product-tile .add-to-cart-global, .product-tile .btn-start-personalize, .product-tile .notify-me {
    display: inline-block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.0714rem;
    min-width: 9em;
    border-radius: 1.7em;
    transition: color .2s ease-in,background-color .2s ease-in,border-color .2s ease-in,box-shadow .2s ease-in;
    color: #fff;
    background-color: #272727;
    display: block;
    font-size: .857rem;
    padding: 0.667em;
    width: 100%;
    text-transform: uppercase;
    border-color: #272727;
    margin: 0.333em 0 0;
}

.row.cart-and-ipay .add-to-cart:hover {
    background-color: white;
    color : black;
    box-shadow: 0 2px 7px -2px #333;
    border: 1px solid #f5f5f5;
}

.tile-go-detail .btn.btn-default.btn-go-details:hover {
    color: #fff;
    background-color: #272727;
    border-color: #272727;
}

.product-tile .product-comparition {
    padding-bottom: 0.614em;
    padding-top: 0.614em;
    border-top: 1px solid #6b6b6b;
    margin-left: -3px;
    margin-right: -3px;
}

.product-tile .product-comparition .checkbox {
    font-size: .857rem;
    cursor: pointer;
    color: #6b6b6b;
    position: relative;
    padding-left: 25px;
}

.product-tile .product-comparition .checkbox:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    border: 1px solid #6b6b6b;
    background: 0 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.product-tile .product-comparition .checkbox:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transform: translateY(-50%) scale(0);
    transition: .3s ease-in;
}

.product-tile .product-comparition .checkbox:after {
    background: linear-gradient(90deg,#02549d,#c11c8a);
    background: -webkit-gradient(to right,#02549d,#c11c8a);
    background: -o-linear-gradient(to right,#02549d,#c11c8a);
}

.product-tile .product-comparition .checkbox:after {
    background: linear-gradient(90deg,#02549d,#c11c8a);
    background: -webkit-gradient(to right,#02549d,#c11c8a);
    background: -o-linear-gradient(to right,#02549d,#c11c8a);
}


.product-tile .product-comparition .checkbox:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    border: 1px solid #6b6b6b;
    background: 0 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.product-tile .product-comparition .checkbox.checked:after {
    transform: translateY(-50%) scale(1);
}


.checkboxSpec {
    font-size: 14px;
    cursor: pointer;
    color: rgb(39, 39, 39);
    position: relative;
    padding-left: 25px;
}

.checkboxSpec:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    border: 1px solid #6b6b6b;
    background: 0 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.checkboxSpec:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transform: translateY(-50%) scale(0);
    transition: .3s ease-in;
}

.checkboxSpec:after {
    background : black
}

.checkboxSpec:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    border: 1px solid #6b6b6b;
    background: 0 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.checkboxSpec.checked:after {
    transform: translateY(-50%) scale(1);
}

.mobile-device {
    display: flex;
}


@media (min-width: 768px)
{
    .product-tile .product-comparition {
        display: none;
    }
    .product-tile .add-to-cart, .product-tile .add-to-cart-global, .product-tile .btn-start-personalize, .product-tile .notify-me {
        font-size: 1rem;
    }
    .product-tile .btn-go-details {
        font-size: 1rem;
    }
    .product-tile .size-selection {
        padding-right: 0;
        padding-left: 0;
    }
    .product-grid .product-tile .tile-body {
        margin-bottom: 110px;
    }
    .product-grid-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .product-grid {
        margin-left: -3px;
        margin-right: -3px;
    }
    .product-grid .product-tile-col {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 6px;
    }
    .product-tile .image-container .tile-image {
    }
}  

@media (min-width: 992px)
{
    .product-tile .tile-body .price .strike-through {
        padding-top: 0.45em;
    }
    .product-tile .tile-body .price {
        font-size: 1.257rem;
        margin-bottom: 0.689em;
        margin-top: 0.267em;
    }
    .product-tile .pdp-link {
        font-size: .857rem;
    }
    .product-tile .product-collection h4 {
        font-size: 1.1429rem;
    }
    .product-tile .size-selection .attr-item a {
        font-size: .857rem;
        padding-left: 0.75em;
        padding-right: 0.75em;
    }
    .product-tile .size-selection {
        font-size: .914rem;
    }
    .product-tile .attribute .attr-item {
        padding-left: 3px;
        padding-right: 3px;
        margin-right: 0;
        margin-left: 0;
    }
    .product-tile .color-swatchs {
        padding-left: 2.3em;
        padding-right: 2.3em;
        min-height: 44px;
    }
    .product-tile .color-quantity {
        padding-top: 0.714em;
    }
    .product-tile .attribute {
        padding-top: 0;
    }
    .product-tile .tile-body .swatch-row {
        min-height: 67px;
    }
    .product-grid .product-tile .tile-body {
        margin-bottom: 100px;
    }
    .product-tile .image-container .tile-image {
        min-height: calc(227.5px - 1.4em);
        transition: .3s ease-in;
    }
    .product-tile .image-container {
        overflow: hidden;
    }
    .product-grid {
        background-color: #f6f7f9;
        padding-left: 6px;
        padding-right: 6px;
    }
    .search-results {
        background: 0 0;
    }
    .product-grid .product-tile-col {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        min-height: 510px;
    }
    .product-tile .color-swatchs .attr-item a {
        width: 17px;
        height: 17px;
    }
    .product-tile .color-swatchs .attr-item.selected .attr-selector {
        width: 27px;
        height: 27px;
    }
}  

@media (min-width: 1200px){

    .mobile-device {
        display: none;
    }
    .product-tile .product-comparition {
        display: block;
    }
    .product-tile .product-collection h4 {
        font-size: 1.2857rem;
    }
    .product-tile .size-selection {
        padding-bottom: 0.5em;
    }
    .product-tile .color-swatchs .attr-item {
        padding-left: 5px;
        padding-right: 5px;
    }
    .product-tile .image-container .tile-image {
        min-height: calc(222px - 0.5355rem - 0.572em);
    }
    .refinement-wrapper .refinements .content li {
        font-size: 1rem;
        border: none;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        padding-left: 2.857em;
    }
    .refinement-wrapper .refinements ul.refinement-colour {
        padding-left: 1.442em;
        padding-right: 0.5em;
    }
    .refinement-wrapper .refinements .content {
        padding-bottom: 1em;
    }
    .refinement-wrapper .block-head .block-title {
        cursor: pointer;
        font-size: 1.1429rem;
        font-family: 'Montserrat', sans-serif !important;
        margin-bottom: 0;
        padding-top: 1em;
        padding-bottom: 1em;
        text-align: left;
    }
    .refinement-wrapper .block-head .block-title span{
        position: relative;
        display: inline-block;
        min-width: 5em;
    }
    .product-grid {
        padding-left: 17px;
        padding-right: 17px;
        padding-top: 20px;
    }
    .search-results-head {
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
    }
    .refinement-bar {
        -ms-flex: 0 0 17.7%;
        flex: 0 0 17.7%;
        max-width: 17.7%;
    }
    .d-xl-block {
        display: block!important;
    }
    .product-grid-wrapper {
    -ms-flex: 0 0 82.3%;
    flex: 0 0 82.3%;
    max-width: 82.3%;
    padding-left: 1.071rem;
    padding-right: 1.071rem;
    }
    .refinement-wrapper .refinements ul.refinement-colour li .swatch-circle:hover {
        border-color: #272727;
        border-width: 0.143rem;
    }
}

@media (min-width: 1600px)
{
    .product-tile .product-comparition .checkbox {
        font-size: .7856rem;
    }
    .product-tile .product-comparition {
        padding-bottom: 0.714em;
        padding-top: 0.714em;
    }
    .product-tile .add-to-cart, .product-tile .add-to-cart-global, .product-tile .btn-start-personalize, .product-tile .notify-me {
        font-size: 1.25rem;
        padding: 0.567em;
    }
    .product-tile .btn-go-details {
        font-size: 1.25rem;
        padding: 0.567em;
    }
    .product-tile .tile-body .price .strike-through {
        padding-top: 0.9em;
    }
    .product-tile .tile-body .price {
        font-size: 1.5714rem;
    }
    .product-tile .pdp-link {
        font-size: .929rem;
    }
    .product-tile .product-collection h4 {
        font-size: 1.4286rem;
    }
    .product-tile .size-selection .attr-item a {
        font-size: 1.0714rem;
    }
    .product-tile .size-selection {
        font-size: 1.1429rem;
    }
    .product-tile .color-swatchs {
        min-height: 51px;
    }
    .product-tile .tile-body .swatch-row {
        min-height: 74px;
    }
    .product-grid .product-tile .tile-body {
        margin-bottom: 110px;
    }
    .product-tile .image-container .tile-image {
        /* min-height: calc(284px - 0.5355rem - 0.572em); */
    }
    .refinement-wrapper .refinements .content li {
        font-size: 1.1429rem;
    }
    .refinement-wrapper .block-sort ul li.selected:after {
        width: 10px;
        height: 10px;
    }
    .refinement-wrapper .block-sort ul {
        font-size: 1.1429rem;
    }
    .search-results>.container {
        max-width: 1440px;
    }
    .refinement-wrapper .refinements ul.refinement-colour {
        padding-left: 1.9em;
    }
    .product-grid .product-tile-col {
        min-height: 570px;
    }
    .product-tile .attribute .attr-item {
        padding-left: 4px;
        padding-right: 4px;
    }
    .product-tile .color-swatchs .attr-item {
        padding-left: 8px;
        padding-right: 8px;
    }
    .product-tile .color-swatchs .attr-item a {
        width: 22px;
        height: 22px;
    }
    .product-tile .color-swatchs .attr-item.selected .attr-selector {
        width: 34px;
        height: 34px;
    }
}    