footer#footer {
    background: #272727;
    color: #FFF;
    margin-top: 25px;
}

footer#footer {
    margin-top: 0;
}

footer#footer .footerMain {
    padding: 50px 0px;
}

footer#footer .footerMain h4 {
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
    position: relative;
}

#footer ul, #footer ol {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

footer#footer .footerMain .footerMainAddress ul li {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

footer hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    width: 100%;
}

footer svg {
    margin-right: 10px;
    font-size: 20px;
    width: 25px;
}

footer#footer .footerMain .footerMainList ul li {
    padding: 5px 0px;
}

footer#footer .footerMain .footerMainList ul li a {
    color: #bbb;
    font-weight: 400;
    transition: ease-in 0.3s;
}

.fb_iframe_widget {
    display: inline-block;
    position: relative;
}

footer#footer .footerMain .footerMainList .newletterSocial {
    margin-top: 10px;
}

footer#footer .is-showthis {
    display: block;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li {
    display: inline-block;
    margin: 0px 5px;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li {
    display: inline-block;
    margin: 0px 5px;
}

footer#footer .footerMain .footerMainList ul li a {
    color: #bbb;
    font-weight: 400;
    transition: ease-in 0.3s;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li a {
    border: 1px solid #888;
    border-radius: 50%;
    font-size: 18px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: block;
}

.lni, [class*=" lni-"], [class^=lni-] {
    display: inline-block;
    font-style: normal!important;
    font-weight: 400!important;
    font-family: LineIcons!important;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li a i {
    color: #888;
    font-size: 16px;
    line-height: 28px;
}

.lni-facebook:before {
    content: "\ea2c";
}

footer#footer .footerMain .footerMainList .newletterSocial ul li a i {
    color: #888;
    font-size: 16px;
    line-height: 28px;
}

.lni-instagram:before {
    content: "\ea3d";
}

footer#footer .footerMain .footerMainList .newletterSocial ul li:hover i {
    color: #ffffff;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li:hover.newletterSocialFacebook a {
    background: #4064AC;
    border: 1px solid #4064AC;
}

footer#footer .footerMain .footerMainList .newletterSocial ul li:hover.newletterSocialInstagram a {
    background: #EEBC52;
    border: 1px solid #EEBC52;
}

footer#footer .footerBottom {
    background: #272727;
    color: #FFF;
    padding: 15px 0;
    border-top: 1px dotted #e1e1e1;
}

footer .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

footer .footerBottom .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

footer#footer .footerBottom p {
    margin: 0;
}

@media (min-width: 576px)
{
    footer .footerBottom .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px)
{
    footer .footerBottom .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    footer .footerBottom .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

@media (min-width: 992px)
{
    footer .footerBottom .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    footer .footerBottom .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    footer#footer .footerBottom .row div:last-child {
        text-align: right;
    }
}