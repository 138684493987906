.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden {
    background-color: black;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.colorblue {
    background: linear-gradient(90deg, #3393d0, #6ac6b1);
    background: -webkit-gradient(to right, #3393d0, #6ac6b1);
    background: -o-linear-gradient(to right, #3393d0, #6ac6b1);
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.colorred {
    background: linear-gradient(90deg, #d31e47, #f37a21);
    background: -webkit-gradient(to right, #d31e47, #f37a21);
    background: -o-linear-gradient(to right, #d31e47, #f37a21);
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.colororange {
    background: linear-gradient(90deg, #f37a21, #d31e47);
    background: -webkit-gradient(to right, #f37a21, #d31e47);
    background: -o-linear-gradient(to right, #f37a21, #d31e47);
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.colorgreen {
    background: linear-gradient(90deg, #81c341, #ffc113);
    background: -webkit-gradient(to right, #81c341, #ffc113);
    background: -o-linear-gradient(to right, #81c341, #ffc113);
}

.swiper-slide.swiper-slide-active p {
    color: #fff;
    font-weight: 600;
    color: #212529;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
    width: 100%;
}


/* ------------------------------------------------------------- */

.menu-collapse.header {
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: none;
    margin-top: 0;
    justify-content: center;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 0;
    min-height: 200px;
    padding-top: 0;
    padding-bottom: 20px;
    /* border-top: 1px solid #f6f6f6; */
    transition: .3s ease-in-out;
    z-index: 2000;
    position: relative;
    display: flex;
}

.site-search {
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: none;
    margin-top: 0;
    justify-content: center;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 0;
    min-height: 100px;
    padding-top: 0;
    padding-bottom: 20px;
    border-top: 1px solid #f6f6f6;
    transition: .3s ease-in-out;
    z-index: 2000;
    position: relative;
    display: flex;
}

.menuTab {

    background: #fff;
    position: fixed;
    width: 400px;
    top: 0;
    right: 0;
    height: 100%;
    box-shadow: 0px 2px 4px rgba(40, 40, 40, 0.2);
    transition: all 0.3s linear;
    transform: translateX(100%);
    z-index: 10000;
    overflow-y: scroll;
}

.menuTab.active {
    transform: translateX(0%);
}
.site-overlay {
    background: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 7777;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms linear;
}

.site-overlay.active {
    opacity: 1;
    visibility: visible;
}

.site-nav.style--sidebar {
    position: fixed;
    width: 480px;
    height: 100vh;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 8888;
    overflow: hidden;
    -ms-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}


.site-nav.style--sidebar.active {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.site-close-handle {
    position: absolute;
    top: 35px;
    right: 0px;
    width: 35px;
    height: 35px;
    z-index: 999;
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    -ms-transition: transform 150ms linear;
    -webkit-transition: transform 150ms linear;
    transition: transform 150ms linear;
}

.site-nav.style--sidebar .site-nav-container {
    width: calc(100% + 20px);
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.site-nav.style--sidebar .site-nav-container-last {
    width: calc(100% - 0px);
    position: relative;
    overflow: hidden;
    min-height: 100%;
    padding: 60px 70px 250px;
}

.site-nav.style--sidebar.show-filters .site-nav-container-last, .site-nav.style--sidebar.show-cart .site-nav-container-last, .site-nav.style--sidebar.show-search .site-nav-container-last {
    padding-bottom: 100px;
}
.site-nav.style--sidebar .site-nav-container .title {
    font-size: 14px;
    text-transform: uppercase;
    margin: 3px 0 0 0;
    font-weight: 700;
    font-size: 25px;
}

.site-nav form.searchform, .site-nav .main-navbar, .cart-view {
    margin-top: 50px;
}

:before, :after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after {
    display: table;
    content: " ";
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical>.btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after {
    clear: both;
}

.cart-view .line {
    float: left;
    width: 100%;
    border-top: 2px solid #000000;
    margin: 10px 0px;
}

.cart-view table {
    width: 100%;
    border-spacing: 0;
    /* border-collapse: separate; */
}

table#cart-view tr {
    border-bottom: 1px dotted #bcbcbc;
}

img.side-cart{
    vertical-align: middle;
    width: 85px;
    height: 85px;
    border: 1px solid #ededed;
    margin-right: 10px;
    max-width: none;
}

table#cart-view tr td, table#cart-view tr th {
    padding: 10px;
    text-align: left;
}

.cart-view table td a.pro-title-view {
    float: left;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

#cart-view .img{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
    line-height: 1.4;
    font-size: 15px;
    color: #272727;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    text-align: left;
    padding-left: 10px;
    border: none!important;
}


#cart-view .img a{    
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
    line-height: 1.4;
    font-size: 15px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: #272727;
    text-decoration: none;
    outline: none;
    transition: opacity 150ms linear, color 150ms linear, background 150ms linear;
}

#cart-view tr td:not(.img) {
    padding: 10px 0;
    position: relative;
    padding-right: 20px;
}
#cart-view .variant {
    font-size: 12px;
    float: left;
    width: 100%;
    margin: 5px 0 8px;
    opacity: 0.66;
}

span.pro-quantity-view {
    position: relative;
    vertical-align: top;
    padding-right: 15px;
    display: inline-block;
    margin-right: 10px;
}

span.pro-quantity-view {
    float: left;
    width: auto;
    background: #ededed;
    text-align: center;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1;
    margin-right: 12px;
}

span.pro-price-view {
    display: block;
    text-align: center;
    float: left;
    line-height: 26px;
    font-weight: 500;
    opacity: 0.7;
}
.remove-cart {
    position: absolute;
    right: 10px;
    top : 10px
}

.text-left {
    text-align: left;
}
#cart-view tr:last-child {
    border: none;
}

table.table-total tr td:first-child, table.table-total tr th:first-child {
    padding-left: 10px;
}

table.table-total tr td:last-child, table.table-total tr th:last-child {
    padding-right: 10px;
}

table.table-total tr td, table.table-total tr th {
    padding: 10px;
    text-align: left;
}

.buttonHol {
    position: relative;
    display: inline-block;
    padding: 10px 28px;
    line-height: normal;
    color: #fff;
    border: 1px solid #000000;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    background-color: transparent;
    -webkit-transition: color 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86),border 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: color 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86),border 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    z-index: 1;
    overflow: hidden;
}

.buttonHol:before {
    position: absolute;
    content: '';
    display: block;
    left: -2px;
    top: 0;
    right: -2px;
    bottom: 0;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    z-index: -1;
    background-color: #000000;
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: -webkit-transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.cart-view a.linktocheckout, .cart-view a.linktocart {
    padding: 0;
    line-height: 45px;
    height: 45px;
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 500;
}


.buttonHol:hover:before {
    -webkit-transform: scale(0, 1); /* Ẩn background trái khi hover */
    transform: scale(0, 1);
  }

.site-nav form.searchform {
    position: relative;
    padding: 0;
}

form.searchform input.searchinput {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    border: none;
    height: 55px;
    font-size: 15px;
    font-weight: 500;
    padding: 0 55px 0 20px;
    margin: 0;
    display: inline-block;
    border-radius: 0;
    -webkit-appearance: none;
    transition: all 150ms linear;
    outline: none;
}

form.searchform input:focus {
    background: rgba(0, 0, 0, 0.1);
    border :none
}

form.searchform .btn-search {
    width: 55px;
    height: 55px;
    line-height: 64px;
    position: absolute;
    padding: 0;
    top: 0px;
    right: 0px;
    background: transparent;
    transition: opacity 150ms linear;
}

.btn#search-header-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

form.searchform .btn-search svg {
    width: 25px;
    height: 22px;
    opacity: .36;
}

#cartformpage .table-cart .item .right .item-quan .qty-click {
    float: left;
}

.item-quan .qty-click button.qty-btn {
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    color: #abafb2;
    height: 25px;
    padding: 0;
    text-align: center;
    width: 25px;
    border: 1px solid #dadbdd;
    border-radius: 0;
    float: left;
    -webkit-appearance: none;
}

.qty-click button.qtyminus.qty-btn {
    border-right: none;
}

.qty-click button.qtyplus.qty-btn {
    border-left: none;
}

.item-quan .qty-click input.item-quantity {
    font-weight: 500;
    font-size: 15px;
    height: 25px;
    padding: 0;
    text-align: center;
    width: 35px;
    background: #ededed;
    border: 1px solid #dadbdd;
    border-radius: 0;
    float: left;
    -webkit-appearance: none;
}

.small-menu-title {
    position: relative;
    display: block;
    padding: 10px 15px;
    padding-left: 0;
    outline: none;
    border-bottom: 1px dotted #ddd;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    font-size: 15px;
    color: #333333;
    font-weight: 700;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
}

.small-menu-title1 {
    position: relative;
    display: block;
    padding: 10px 15px;
    padding-left: 0;
    outline: none;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    font-size: 15px;
    color: #333333;
    font-weight: 700;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
}

.inner1-menu:last-child .small-menu-title {
    border: none;
}



.small-menu-title2 {
    
    position: relative;
    display: block;
    padding: 10px 15px;
    padding-left: 0;
    outline: none;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    font-size: 15px;
    border-bottom: 1px dotted #ddd;
    color: #333333;
    font-weight: 700;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
}
.item_2.inner2-menu:last-child .small-menu-title2  {
    border: none;
}

.menu-1-show {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
}

.menu-1-show.active {
    height: 100%;
}


@media (max-width: 767px) {
    .cart-view a.linktocheckout, .cart-view a.linktocart {
        font-size: 11px;
    }
    .site-nav.style--sidebar .site-nav-container-last {
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .site-nav.style--sidebar .site-nav-container {
        width: 100%;
    }
    .menuTab {
        width: 80%;
        padding: 0;
        margin: 0;
        right: -5px;
    }
    .site-nav.style--sidebar {
        width: 320px;
    }
    .site-close-handle {
        top: 35px;
        right: 30px;
    }
}